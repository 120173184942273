import React, { useState, useEffect } from 'react';

// Material UI components for UI design
import { Typography, Button, Divider, Container, Box, Grid, useMediaQuery, useTheme } from '@mui/material';
// Custom components and assets
import Slideshow from './SlideShow';
import Footer from './components/Footer';
import { Parallax } from 'react-parallax';
// Images and assets
import dashboardimg from '../src/Assets/dashboard.png';
import uniOnePhoto from '../src/Assets/uniONE-photo.png';
import appstore from '../src/Assets/app_store.png';
import googleplay from '../src/Assets/google_play.png';
import androidappimg from '../src/Assets/androidappimg.png';
import Sec2 from '../src/Assets/slideshowmain.png'
import iPhonef1 from '../src/Assets/iPhonef1.png';


// Features list for the swipeable views component
const features = [
  { title: 'Feature 1', phoneImage: iPhonef1 },
  { title: 'Feature 2', phoneImage: iPhonef1 }, // Assuming you intended to use iPhonef1 again, replace as necessary
  { title: 'Feature 3', phoneImage: iPhonef1 },
  { title: 'Feature 4', phoneImage: iPhonef1 },
];

function MainPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0);
  const [showGradient, setShowGradient] = useState(Array(4).fill(false));
  const [isLargeScreen, setIsLargeScreen] = useState(window.visualViewport.width > 768); 
  const [isHovered, setIsHovered] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Hover effect style, conditional based on screen size
  const hoverEffect = isLargeScreen ? {
    transform: isHovered ? "perspective(1000px) rotateY(0deg) rotateX(0deg)" : "perspective(1000px) rotateY(20deg) rotateX(15deg)",
    transition: "transform 0.5s ease",
  } : {};
  
  const boxStyle = {
    ...hoverEffect, // Conditionally include hover effect
    width: '100%',
    maxWidth: '400px',
    height: 'auto',
    minWidth: '300px',
  };

 


  const handleScroll = () => {

    const windowHeight = window.innerHeight;
    const newShowGradient = showGradient.map((_, index) => {
      const element = document.getElementById(`feature-box-${index + 1}`);
      if (element) {
        const boxTop = element.getBoundingClientRect().top;
        if (isLargeScreen) {
          // Larger screen
          if (index === 0 || index === 2) { // feature-box-1 and feature-box-3
            return boxTop < windowHeight / 2;
          } else { // feature-box-2 and feature-box-4
            return boxTop < windowHeight - 90;
          }
        } else {
          // small screen, feature 1 and 2 a group, feature 3 and 4 a group
            return boxTop < windowHeight / 2;
        }
      }
      return false;
    });
    setShowGradient(newShowGradient);
  };

  // First useEffect: Handles dynamic adjustments based on screen resize and scroll events.
useEffect(() => {
  // Call handleScroll initially to set up any scroll-based effects right away.
  handleScroll(); 

  // handleResize adjusts the state based on screen width, determining if it's a large screen.
  const handleResize = () => {
    setIsLargeScreen(window.visualViewport.width > 768);
  };

  // Add event listeners for resize and scroll to dynamically adjust UI or state.
  window.addEventListener('resize', handleResize);
  window.addEventListener('scroll', handleScroll);

  // Cleanup function to remove event listeners when the component unmounts or re-renders.
  // This prevents memory leaks and ensures that the event listeners are only attached once.
  return () => {
    window.removeEventListener('resize', handleResize);
    window.removeEventListener('scroll', handleScroll);
  };
},[]); // The empty dependency array means this effect runs only on mount and unmount.

// Second useEffect: Automatically cycles through features on mobile devices.
useEffect(() => {
  // This effect only applies when isMobile is true, creating an automatic slideshow effect.
  if (isMobile) {
    // Set an interval to change the selected feature index every 4 seconds.
    const intervalId = setInterval(() => {
      setSelectedFeatureIndex((prevIndex) =>
        // Loop back to the first feature after reaching the end of the features array.
        prevIndex === features.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000); // Change slide every 4 seconds

    // Cleanup function to clear the interval when the component unmounts or if isMobile changes.
    // This ensures that the interval is not left running unintentionally.
    return () => clearInterval(intervalId);
  }
},[isMobile]); // Depend on isMobile so that the effect re-runs if the screen size crosses the mobile breakpoint.

  return (
    <div>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column-reverse' : 'row'}  // Stacks content vertically on mobile, horizontally on larger screens
          justifyContent="center" // Centers children horizontally
          alignItems="center" // Centers children vertically
          minHeight="90vh" // Ensures the container takes at least 90% of the viewport height
          bgcolor="white" // Sets background color to white
          p={2} // Adds padding around the container
          sx={{
            position: 'relative', // Positions the container relatively for potential absolute children
          }}
        >
          {/* Text content container */}
          <Box
            display="flex"
            flexDirection="column" // Stacks children vertically
            justifyContent="center" // Centers children vertically
            alignItems={isMobile ? 'center' : 'center'} // Centers text on mobile, aligns to start on larger screens
            sx={{
              maxWidth: isMobile ? '100%' : '50%', // Full width on mobile, half width on larger screens
              textAlign: isMobile ? 'center' : 'left', // Text alignment adjusts based on screen size
              order: isMobile ? 1 : 0, // Ensures text appears above the image on mobile by reversing order
              marginRight: 'auto', // Automatically adjusts right margin
              marginLeft: 'auto', // Automatically adjusts left margin
              overflow: 'hidden', // Hides overflow to prevent text from overflowing
            }}
          >
            {/* Welcome message */}
            <Typography variant="h1" gutterBottom align="center" sx={{ fontSize: { xs: '2rem', sm: '3rem', md: '4rem' }}}>
              Welcome to <span style={{color: 'black'}}>uni</span><span style={{color: '#FFA861'}}>ONE</span>!
            </Typography>
            <div textAlign="center"> 
            <Typography color="black" variant="h6" textAlign="center">
                Your social life shouldn't be harder than your Major!
            </Typography>
            </div>
          </Box>
          {/* Conditionally rendered image for non-mobile screens */}
          {!isMobile && (
            <Box
              component="img"
              src={uniOnePhoto}
              sx={{
                maxWidth: '40%', // Limits image width to 40% of its container
                height: 'auto', // Adjusts height automatically to maintain aspect ratio
                order: 2, // Positions the image on the right for larger screens
                marginRight: 'auto', // Automatically adjusts right margin
              }}
            />
          )}
        </Box>
        
      
      
      
      <Grid container direction="column"  alignItems="center" style={{ backgroundColor:'#f5f5f5',width:'100%'}}>
        <Typography variant="h4" gutterBottom sx={{ fontSize: '2.5rem', fontFamily: 'cabin', textAlign: 'center', marginTop: '50px' }}>
          {/* Heading section with the theme and a brief introduction */}
          Anytime, Anywhere
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1.5rem', fontFamily: 'cabin', textAlign: 'center', maxWidth: '600px' }}>
          Download our app to enjoy the full experience of uniONE on your mobile device. Stay connected and manage your events on the go!
        </Typography>
        
        {/* Child Grid container holding feature boxes, main features of the app, and download buttons */}
        <Grid container item xs={12} justifyContent="space-around" alignItems="center" spacing={2}>
          
          <Grid item xs={12} sm={7} md={6} lg={3}>
            <Box id="feature-box-1" sx={{ transition: 'opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.5s ease', transform: 'scale(1)', 
            boxShadow: '6px 6px 10px rgba(255, 165, 0, 0.8)', 
            backgroundImage: 'linear-gradient(90deg, #ffffff 0%, #ffffff 100%)', '&:hover': {
              transform: 'scale(1.05)',
            },
            color: '#000', padding: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 260,
            borderRadius:6, overflow: 'hidden', opacity: showGradient[0] ? 1 : 0, width: '100%', maxWidth: { xs: '80%', sm: '90%', md: '100%', lg:'80%' }, mx: 'auto', marginTop: '80px', minWidth: '300px', maxHeight: '100%',}}>
              <Typography fontWeight="bold" sx={{ fontFamily: 'Cabin', marginTop: 2}}>Discover Your Campus Like Never Before</Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center', // Centers horizontally
                  alignItems: 'center', // Centers vertically
                  height: '100%', // Make sure the Box takes full height of its parent to center vertically correctly
                  width: '100%',
                }}
              > 
                <Typography 
                  variant={isSmallScreen ? 'body2' : 'body1'} 
                  sx={{ 
                    fontFamily: 'Cabin', 
                    lineHeight: isSmallScreen ? '1.3' : '1.4', 
                    marginBottom: isSmallScreen ? 1 : 2,
                    textAlign: 'center', // Ensures the text itself is centered within the Typography component
                  }}>
                  Dive into the heart of your university life with uniONE! From campus events to student clubs, 
                  everything you need to stay informed and connected is at your fingertips. 
                  Download uniONE and start exploring your campus community today!
                </Typography>
              </Box>
              <div style={{ flexGrow: 1 }}></div>
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
                paddingBottom: '16px',
                px: 2,
                pl: 3,
                }}>
                
              </Box>
            </Box>
            <Box id="feature-box-2" sx={{ transition: 'opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.5s ease', transform: 'scale(1)', 
            boxShadow: '6px 6px 10px rgba(255, 165, 0, 0.8)', 
            backgroundImage: 'linear-gradient(90deg, #ffffff 0%, #ffffff 100%)', '&:hover': {
              transform: 'scale(1.05)',
            }, color: '#000', padding: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
            height: 260,borderRadius:6 ,marginTop: { xs: 2, md: 15, lg: 40}, 
            overflow: 'hidden', opacity: showGradient[1] ? 1 : 0, width: '100%', maxWidth: { xs: '80%', sm: '90%', md: '100%', lg:'80%'  }, mx: 'auto', minWidth: '300px', maxHeight: '100%'}}>
              <Typography fontWeight="bold" sx={{ fontFamily: 'Cabin', marginTop: 2}}>Streamline Your Academic Journey</Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center', // Centers horizontally
                  alignItems: 'center', // Centers vertically
                  height: '100%', // Make sure the Box takes full height of its parent to center vertically correctly
                  width: '100%',
                }}
              > 
                <Typography variant={isSmallScreen ? 'body2' : 'body1'} 
                  sx={{ 
                    fontFamily: 'Cabin', 
                    lineHeight: isSmallScreen ? '1.3' : '1.4', 
                    marginBottom: isSmallScreen ? 1 : 2, 
                    textAlign: 'center',
                    }}>
                  With uniONE, managing your academic life has never been easier. Access your class schedule, track assignments, 
                  and collaborate with classmates all in one place. 
                  Elevate your study game by downloading uniONE now!
                </Typography>
                </Box>
              <div style={{ flexGrow: 1 }}></div>
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
                paddingBottom: '16px',
                px: 2,
                pl: 3,
                }}>

              
              </Box>
            </Box>
            
          </Grid>
          {/* App preview image, could be screenshots or related visual elements */}
    
          <Grid item xs={12} sm={12} md={12} lg={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: theme.spacing(2), margin: theme.spacing(1) }}>
            <Box component="img" src={androidappimg} alt="App Preview" sx={boxStyle} onMouseEnter={() => isLargeScreen && setIsHovered(true)} onMouseLeave={() => isLargeScreen && setIsHovered(false)} />
            <Box mt={2} sx={{ display: 'flex', justifyContent: 'center', width: '100%', gap: theme.spacing(2) }}>
            
            
              {/* App Store Button */}
              <Button
                component="a"
                href="https://forms.gle/tL8hGfZTSVizgpEb8"
                sx={{
                  width: 150, 
                  height: 50, 
                  padding: 0,
                  '& img': {
                    width: '100%', 
                    height: '100%', 
                    // objectFit: 'contain',
                    marginTop: isLargeScreen ? '90%' : isSmallScreen ? '50%' : '50%', 
                  },
                }}
              >
                <img src={appstore} alt="Download on the App Store" />
              </Button>
              {/* Google Play Button */}
              <Button
                component="a"
                href="https://forms.gle/tL8hGfZTSVizgpEb8"
                sx={{
                  width: 150, 
                  height: 50, 
                  padding: 0,
                  '& img': {
                    width: '100%', 
                    height: '100%', 
                    // objectFit: 'contain', 
                    marginTop: isLargeScreen ? '90%' : isSmallScreen ? '50%' : '50%',
                  },
                }}
              >
                <img src={googleplay} alt="Get it on Google Play" />
              </Button>
            </Box>
          </Grid>
         
          


          
          <Grid item xs={12} sm={7} md={6} lg={3}>
            <Box id="feature-box-3" sx={{ transition: 'opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.5s ease', transform: 'scale(1)', 
            boxShadow: '6px 6px 10px rgba(255, 165, 0, 0.8)', 
            backgroundImage: 'linear-gradient(90deg, #ffffff 0%, #ffffff 100%)', '&:hover': {
              transform: 'scale(1.05)',
            }, color: '#000', padding: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 260,
            borderRadius:6, overflow: 'hidden', opacity: showGradient[2] ? 1 : 0, width: '100%', maxWidth: { xs: '80%', sm: '90%', md: '100%', lg:'80%'  }, mx: 'auto', marginTop: isLargeScreen ? '80px':'20px', minWidth: '300px', maxHeight: '100%',}}>
              <Typography fontWeight="bold" sx={{ fontFamily: 'Cabin', marginTop: 2}}>Unlock Student-Only Discounts</Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center', // Centers horizontally
                  alignItems: 'center', // Centers vertically
                  height: '100%', // Make sure the Box takes full height of its parent to center vertically correctly
                  width: '100%',
                }}
              > 
              <Typography 
              variant={isSmallScreen ? 'body2' : 'body1'} 
              sx={{ 
                fontFamily: 'Cabin', 
                lineHeight: isSmallScreen ? '1.3' : '1.4', 
                marginBottom: isSmallScreen ? 1 : 2, 
                textAlign: 'center',
                }}>
              Save big with exclusive deals and discounts available only through uniONE. From textbooks to coffee, 
              your student ID unlocks a world of savings. 
              Get more out of your student budget by downloading UniONE today!
              </Typography>
              </Box>
              <div style={{ flexGrow: 1 }}></div>
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
                paddingBottom: '16px',
                px: 2,
                pl: 3,
                }}>
                
              </Box>
            </Box>
            <Box id="feature-box-4" sx={{ transition: 'opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.5s ease', transform: 'scale(1)', 
            boxShadow: '6px 6px 10px rgba(255, 165, 0, 0.8)', 
            backgroundImage: 'linear-gradient(90deg, #ffffff 0%, #ffffff 100%)', '&:hover': {
              transform: 'scale(1.05)',
            }, color: '#000', padding: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 260,
            borderRadius:6 , marginTop: { xs: 2, md: 15, lg: 40}, overflow: 'hidden', opacity: showGradient[3] ? 1 : 0, width: '100%', maxWidth: { xs: '80%', sm: '90%', md: '100%', lg:'80%'  }, mx: 'auto', minWidth: '300px', maxHeight: '100%',}}>
              <Typography fontWeight="bold" sx={{ fontFamily: 'Cabin', marginTop: 2}}>Never Miss Out On What Matters</Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center', // Centers horizontally
                  alignItems: 'center', // Centers vertically
                  height: '100%', // Make sure the Box takes full height of its parent to center vertically correctly
                  width: '100%',
                }}
              > 
              <Typography 
              variant={isSmallScreen ? 'body2' : 'body1'} 
              sx={{ 
                fontFamily: 'Cabin', 
                lineHeight: isSmallScreen ? '1.3' : '1.4', 
                marginBottom: isSmallScreen ? 1 : 2, 
                textAlign: 'center',
                }}>
              Stay in the loop with real-time notifications on campus news, events, and important announcements. 
              Whether you're in the library or on the go, uniONE keeps you updated. Download the app and stay connected to your university life!
              </Typography>
              </Box>
              <div style={{ flexGrow: 1 }}></div>
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
                paddingBottom: '16px',
                px: 2,
                pl: 3,
                }}>
                
              </Box>
            </Box>
            
          
          </Grid>
        </Grid>
        {/* "Download Now" button, might be hidden based on the visibility property */}
        <Button variant="contained" color="primary" href="YOUR_DOWNLOAD_LINK_HERE" style={{ color: 'white',backgroundColor:'black', padding: '12px 24px', fontSize: '1.1rem', marginTop: '40px', marginBottom: '50px', width: 'auto', maxWidth: '100%', visibility: 'hidden' }}>
          Download Now
        </Button>
      </Grid>

      <>
       {/* Visual separation and aesthetic component */}
       <Divider />
     
       {/* Parallax effect for a more dynamic visual experience */}
       <Parallax
         bgImage={Sec2}
         strength={500} 
         bgImageStyle={{ minHeight: '100%', backgroundPosition: 'center top',}}
       >
         {/* Central content box within the parallax effect */}
         <Box
           display="flex"
           flexDirection="column"
           justifyContent="center"
           alignItems="center"
           minHeight="70vh"
           color="white"
           sx={{
             mt: '-100px', 
             width: '100%', 
             flexDirection: 'column',
           }}
         >
           {/* Inner content box with specific styling */}
           <Box
             alignItems="center"
             sx={{
               backgroundColor: 'rgba(0, 0, 0, 0.5)',
               padding: '2rem',
               borderRadius: '8px',
               margin: '15rem',
               maxWidth: '1000px',
               width: '100%',
               overflow: 'auto',
             }}
           >
             {/* Slideshow component showcasing various features or images */}
             <Slideshow />
           </Box>
         </Box>
       </Parallax>
     
       {/* Visual separation and aesthetic component */}
       <Divider />
     </>
     
     <Container maxWidth="lg" sx={{ my: 8 }}>
      {/* Heading for the Dashboard section */}
      <Typography variant="h2" gutterBottom align="center" sx={{ fontFamily: 'Cabin', mb: 4, fontSize: '2.5rem' }}>
        Dashboard
      </Typography>
      
      {/* Container for Dashboard features and information */}
      <Grid container spacing={4} alignItems="center" flexDirection={{ xs: 'column-reverse', md: 'row' }}>
        {/* Textual content describing the Dashboard */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" sx={{ fontSize: '1.25rem', fontFamily: 'Cabin', mb: 2, md: { textAlign: 'left' } }}>
            Welcome to our subscription-based event management dashboard, 
            the ultimate tool for businesses to streamline and elevate their event planning process. 
            Tailored specifically for event professionals, 
            our dashboard offers a comprehensive suite of features to manage every aspect of your events with ease and efficiency.
          </Typography>
          {/* Button to learn more about the Dashboard, triggers navigation to the About page */}
          <Box display="flex" justifyContent={{ xs: 'center', md: 'flex-start' }} >
            <Button variant="contained" color="primary" href="/about" sx={{ bgcolor: 'primary.main', color: 'white', py: '12px', px: '24px', fontSize: '1.1rem', mt: 3, '&:hover': { bgcolor: 'white', color: '#EF7B54'} }}>
              Learn More
            </Button>
          </Box>
        </Grid>
        {/* Image showcasing the Dashboard */}
        <Grid item xs={12} md={6} display="flex" justifyContent="center">
          <Box
            component="img"
            src={dashboardimg}
            alt="Dashboard Preview"
            sx={{ width: '100%', maxWidth: '600px', height: 'auto', boxShadow: 3, borderRadius: 2 }}
          />
        </Grid>
      </Grid>
    </Container>

     
     {/* Footer section for additional navigation or information */}
     <Grid style={{ marginTop: '200px', width:'100%' }}>
       <Footer />
     </Grid>
     
    
    
    </div> 
  );
}

export default MainPage;