import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Collapse, Divider, List, ListItem, ListItemButton, ListItemText, SvgIcon} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, DialogContent} from '@mui/material';
import Wordmark from '../Wordmark/Wordmark'
import Wordmark_white from '../Wordmark/Workdmark_white';
import UnioneLogo from '../Logo/Logo2'
import Cookies from 'js-cookie'; 
import { cookie } from '../../utils';
import { CookieNames } from '../../constants';
import useAuth from './useAuth';
const pages = ['Home', 'About', 'Pricing','Promotions','Events','Organizations','Team'];
const mobilePages = ['Home', 'About', 'Pricing','Promotions','Events','Organizations','Team']

// Unused for now, but will be used for later for when we get login working.
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const entries = ['Login', 'Sign Up']

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(false);
  const [isAtTop, setIsAtTop] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false); // State to control Dialog
  const [openSignUpDialog, setOpenSignUpDialog] = React.useState(false); // New state for Sign Up dialog
  /// changes for student start here 
  const userType = useAuth();
  

  const handleLogoutClick = () => {
    if (userType === 'business') {
      cookie.remove(CookieNames.AUTH_TOKEN, { path: '/', domain: 'unione.cc' });
    } 
    cookie.remove(CookieNames.AUTH_TOKEN);
    Cookies.remove(CookieNames.AUTH_TOKEN); // Assuming this is used for business users
    Cookies.remove('accessToken'); // Assuming this is used for student users
    Cookies.remove('userType'); // Remove the userType cookie to fully clear the user session
  
    // Optionally, if you're using local storage in any part for authentication status
    localStorage.removeItem('isUserAuthenticated');
    
    // Dispatch an event if you need to notify parts of your application about the logout
    window.dispatchEvent(new CustomEvent('authChange'));
    
    // Reload the page or redirect the user to the home page or login page
    window.location.href = '/';
  };

  const getFilteredPages = React.useCallback(() => {
    if (userType === 'student') {
      return pages.filter(page => page !== 'Pricing'); // Assuming 'Pricing' should be hidden for students
    }
    // Default filter for other user types
    return pages.filter(page => !['Promotions', 'Events', 'Organizations'].includes(page));
  }, [userType]); // Re-calculate when userType changes
  
  const filteredPages = getFilteredPages();
  const filteredMobilePages = getFilteredPages(mobilePages);
  const isLoggedIn = userType !== null;

  // first changes end here

  // Function to open dialog
  const handleLoginClick = () => {
    setOpenDialog(true);
  };

  const handleSignUpClick = () => {
    setOpenSignUpDialog(true);
  };

  // Function to close dialog and optionally redirect based on the user's choice
  const handleCloseDialog = (userType, actionType) => {
    if (actionType === 'login') {
      setOpenDialog(false);
    } else if (actionType === 'signup') {
      setOpenSignUpDialog(false);
    }

    if (userType === 'business') {
      window.location.href = 'https://dashboard.unione.cc/';
    } else if (userType === 'student' && actionType === 'signup') {
      // For 'student', redirect to sign-up for now
      window.location.href = '/signup';
    }
    else if (userType === 'student' && actionType === 'login') {
    window.location.href = '/login';
  }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(true);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(false);
  };

  // This scroll event listener is for the navbar to change colors when
  // its at the top vs when its not
  React.useEffect(() => {
    setIsAtTop(window.scrollY===0);

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsAtTop(scrollTop===0);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])
  return (
      <AppBar
        position='sticky' 
        sx={{ display: 'block', background: isAtTop ? 'transparent' : '#272723', boxShadow: 'none' }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
          <IconButton
              edge="start"
              color="inherit"
              aria-label="home"
              sx={{ mr: -1, display: { xs: 'none', md: 'flex' } }}
              onClick={() => window.location.href = '/'} // For React Router
            >
              <SvgIcon component={UnioneLogo} viewBox='0 0 64.5 64.5' />
            </IconButton>

            {/* Wordmark or secondary logo */}
            <IconButton
              sx={{ display: { xs: 'none', md: 'flex' }, mr: -1 }}
              onClick={() => window.location.href = '/'} // For React Router
            >
              <SvgIcon component={isAtTop ? Wordmark : Wordmark_white} viewBox='0 0 64.5 64.5' width={150} />
            </IconButton>
            {/* The uniONE wordmark. Client asks us to change it with the correct font
                but that will be done during sprint 3 for frontend team */}
            {/* <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                display: { xs: 'none', md: 'flex' },
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: isAtTop ? '#272723' : 'white',
                textDecoration: 'none',
                fontSize: '2rem',
            }}
            >
              uni
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 4,
                display: { xs: 'none', md: 'flex' },
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: '#FFA861',
                textDecoration: 'none',
                fontSize: '2rem',
            }}
            >
              ONE
            </Typography> */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(anchorElNav===true) ? handleCloseNavMenu : handleOpenNavMenu }
                sx={{ color: isAtTop ? '#272723' : 'white' }}
              >
                { (anchorElNav===true) ? <CloseIcon /> : <MenuIcon /> } 
              </IconButton>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, mr: 5 }}>
              <IconButton
                onClick={() => window.location.href = '/'} // For traditional navigation
              >
                <SvgIcon component={UnioneLogo} viewBox='0 0 64.5 64.5' />
              </IconButton>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {filteredPages.map((page) => (
                <Button
                  href={ (page==='Home') ? '/' : '/' + page.toLowerCase() }
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ minWidth: 0, my: 2, mr: 2, color: isAtTop ? '#272723' : 'white', display: 'block', textTransform: 'none', fontSize: '1.2rem', ":hover": { color: '#EF7B54' } }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            {/* Login, signup, and logout buttons */}
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
              <Button 
                  onClick={handleLoginClick} // Changed to open the dialog
                  sx={{ my: 2, mr: 2, color: isAtTop ? '#272723' : 'white', display: isLoggedIn ? 'none' : 'block', textTransform: 'none', fontSize: '1.2rem', ":hover": { color: '#EF7B54' } }}
              >
                  Login
              </Button>
              <Button 
                  color='secondary'
                  variant='contained' 
                  key='Signup'
                  onClick={handleSignUpClick} // Changed to open the sign up dialog
                  sx={{ 
                    my: 2, 
                    fontStyle: 'bold', 
                    borderRadius: '30px', 
                    textTransform: 'none', 
                    fontSize: '1rem',
                    color: 'white', 
                    bgcolor: '#EF7B54', 
                    '&:hover': { bgcolor: 'white', color: '#EF7B54'},
                    display: isLoggedIn ? 'none' : ''
                  }}
              >
                Sign Up
              </Button>
              <Dialog
                open={openSignUpDialog}
                onClose={() => handleCloseDialog('student', 'signup')}
                fullWidth={true} // Makes the dialog responsive
                maxWidth="sm" // Sets the maximum width to 'sm' for small devices and up
                sx={{
                  '& .MuiDialog-paper': {
                    borderRadius: 4,
                    p: 3, // Adds padding inside the dialog for a better look
                    width: '100%', // Ensures the dialog uses the full width up to its max
                  }
                }}
              >
                <DialogTitle sx={{
                  textAlign: 'center',
                  justifyContent: 'space-between',
                  fontWeight: 'bold',
                  fontSize: '1.25rem', // Enhances title size for better visibility
                }}>
                {"Are you a student or a organizations?"}
                <IconButton
                  aria-label="close"
                  onClick={() => setOpenSignUpDialog(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                </DialogTitle>
                <DialogContent sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  pt: 2,
                }}>
                  <Button
                    onClick={() => handleCloseDialog('student', 'signup')}
                    sx={{
                      mb: 2, // Margin bottom for spacing between buttons
                      width: '80%', // Button width
                      bgcolor: '#EF7B54', // Primary color
                      color: 'white', // Text color
                      '&:hover': {
                        bgcolor: 'white', // Hover background color
                        color: '#EF7B54', // Hover text color
                        border: '1px solid #EF7B54', // Add border to maintain button size and visibility
                      },
                      borderRadius: 20, // Rounded corners
                      textTransform: 'none', // Keep text case as is
                      fontSize: '1rem',
                      fontWeight: 'bold',
                    }}
                  >
                    Student
                  </Button>
                  <Button
                    onClick={() => handleCloseDialog('business', 'signup')}
                    autoFocus
                    sx={{
                      width: '80%', // Button width
                      bgcolor: '#EF7B54', // Primary color
                      color: 'white', // Text color
                      '&:hover': {
                        bgcolor: 'white', // Hover background color
                        color: '#EF7B54', // Hover text color
                        border: '1px solid #EF7B54', // Add border to maintain button size and visibility
                      },
                      borderRadius: 20, // Rounded corners
                      textTransform: 'none', // Keep text case as is
                      fontSize: '1rem',
                      fontWeight: 'bold',
                    }}
                  >
                    Organizations
                  </Button>
                </DialogContent>
              </Dialog>

              <Button
                color='secondary'
                variant='contained' 
                key='Logout'
                onClick={handleLogoutClick}
                sx={{ 
                  my: 2, 
                  fontStyle: 'bold', 
                  borderRadius: '30px', 
                  textTransform: 'none', 
                  fontSize: '1rem',
                  color: 'white', 
                  bgcolor: '#EF7B54', 
                  '&:hover': { bgcolor: 'white', color: '#EF7B54'},
                  display: isLoggedIn ? '' : 'none'
                }}
              >
                Log Out
              </Button>
            </Box>
          </Toolbar>
        </Container>
        {/* The dropdown view for mobile view */}
        <Collapse in={anchorElNav} sx={{ position: 'absolute', width: '100vw' }}>
          <List
            onClick={handleCloseNavMenu}
            onKeyDown={handleCloseNavMenu} 
            sx={{ bgcolor: "#343430", padding: '8px 0' }} // Adjust padding for better spacing
          >
            {filteredMobilePages.map((page, index) => (
              <React.Fragment key={page + '-mobile'}>
                <ListItem>
                  <ListItemButton 
                    href={(page === 'Home') ? '/' : '/' + page.toLowerCase()}
                    sx={{ '&:hover': { bgcolor: 'rgba(255, 165, 0, 0.2)' } }} // Hover effect
                  >
                    <ListItemText primary={page} sx={{ textAlign: 'center', color: 'white' }}/>
                  </ListItemButton>
                </ListItem>
                {(index === filteredMobilePages.length - 1) ? '' : <Divider component='li' />}
              </React.Fragment>
            ))}

            {/* Adding a visual separation */}
            {!isLoggedIn && <Divider component='li' />}

            {/* Conditionally render Login and Signup for non-logged-in users */}
            {!isLoggedIn && (
              <>
                <ListItemButton onClick={handleLoginClick} sx={{ justifyContent: 'center', color: '#FFA861', '&:hover': { bgcolor: 'rgba(255, 165, 0, 0.2)' } }}>
                  <ListItemText primary="Login" sx={{ textAlign: 'center', fontWeight: 'bold' }} />
                </ListItemButton>
                <ListItemButton onClick={handleSignUpClick} sx={{ justifyContent: 'center', color: '#FFA861', '&:hover': { bgcolor: 'rgba(255, 165, 0, 0.2)' } }}>
                  <ListItemText primary="Sign Up" sx={{ textAlign: 'center', fontWeight: 'bold' }} />
                </ListItemButton>
              </>
            )}

            {/* Display Logout for logged-in users */}
            {isLoggedIn && (
              <>
                <Divider component='li' />
                <ListItemButton onClick={handleLogoutClick} sx={{ justifyContent: 'center', color: '#EF7B54', '&:hover': { bgcolor: 'rgba(255, 165, 0, 0.2)' } }}>
                  <ListItemText primary="Logout" sx={{ textAlign: 'center', fontWeight: 'bold' }} />
                </ListItemButton>
              </>
            )}
          </List>
        </Collapse>


             {/* Dialog for login action */}
             <Dialog
          open={openDialog}
          onClose={() => handleCloseDialog()}
          fullWidth={true} // Makes the dialog responsive
          maxWidth="sm" // Sets the maximum width to 'sm' for small devices and up
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: 4,
              p: 3, // Adds padding inside the dialog for a better look
              width: '100%', // Ensures the dialog uses the full width up to its max
            }
          }}
        >
          <DialogTitle sx={{
            textAlign: 'center', 
            fontWeight: 'bold',
            fontSize: '1.25rem', // Enhances title size for better visibility
          }}>
          {"Are you a student or a organizations?"}
          <IconButton
            aria-label="close"
            onClick={() => setOpenDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          </DialogTitle>
          <DialogContent sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 2,
          }}>
            <Button
              onClick={() => handleCloseDialog('student', 'login')}
              sx={{
                mb: 2, // Margin bottom for spacing between buttons
                width: '80%', // Button width
                bgcolor: '#EF7B54', // Primary color
                color: 'white', // Text color
                '&:hover': {
                  bgcolor: 'white', // Hover background color
                  color: '#EF7B54', // Hover text color
                  border: '1px solid #EF7B54', // Add border to maintain button size and visibility
                },
                borderRadius: 20, // Rounded corners
                textTransform: 'none', // Keep text case as is
                fontSize: '1rem',
                fontWeight: 'bold',
              }}
            >
              Student
            </Button>
            <Button
              onClick={() => handleCloseDialog('business')}
              autoFocus
              sx={{
                width: '80%', // Button width
                bgcolor: '#EF7B54', // Primary color
                color: 'white', // Text color
                '&:hover': {
                  bgcolor: 'white', // Hover background color
                  color: '#EF7B54', // Hover text color
                  border: '1px solid #EF7B54', // Add border to maintain button size and visibility
                },
                borderRadius: 20, // Rounded corners
                textTransform: 'none', // Keep text case as is
                fontSize: '1rem',
                fontWeight: 'bold',
              }}
            >
              Organizations
            </Button>
          </DialogContent>
        </Dialog>
      </AppBar>
  );
}
export default Navbar;