//import * as React from 'react';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views'; // Import SwipeableViews
import { Button, Grid, useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
const steps = [
  {
    label: 'Meet clubs',
    description: `Embark on a journey of exploration and connection with Joining New Clubs, your gateway to discovering 
    and engaging with a myriad of diverse clubs and organizations. Whether you're a seasoned enthusiast seeking to expand 
    your horizons or a newcomer eager to immerse yourself in new experiences, Joining New Clubs offers a seamless platform 
    to discover, join, and participate in clubs that align with your interests and passions.`,
  },
  {
    label: 'Find people',
    description:`On a personal level, meeting new people nurtures our social well-being and enhances our sense of belonging. 
    Building strong connections with others fosters a sense of community and support, providing us with companionship, encouragement, 
    and a shoulder to lean on during challenging times.`,
      
  },
  {
    label: 'Find events',
    description: `Discovering new events is like uncovering hidden treasures in your city. Whether you're seeking cultural experiences, thrilling adventures, or simply looking to unwind 
    and socialize, exploring new events offers a world of possibilities.
    From vibrant music festivals to thought-provoking art exhibitions, there's something for everyone. Dive into the local food scene with tantalizing food fairs or embrace your
    inner adventurer with outdoor excursions and sports events.
    Finding new events isn't just about filling your calendar—it's about enriching your life with memorable experiences and connecting with like-minded individuals. So, step out of your comfort zone, explore the unknown, and let each new event be a chapter in your journey of discovery.`,
  },
  {
    label: 'Find deals',
    description: `Unlocking exclusive deals and discounts adds an extra layer of excitement to your shopping experience. Whether you're scouring the web or exploring your favorite stores, 
    finding deals is like stumbling upon hidden treasures waiting to be discovered.From flash sales on electronics to discounts at your favorite restaurants, there's a deal for every taste
    and preference. Dive into the world of online shopping with limited-time offers and promotional codes, or explore local businesses offering special deals to loyal customers.
    Finding deals isn't just about saving money—it's about maximizing value and indulging in the things you love without breaking the bank. So, keep your eyes peeled, stay savvy, 
    and let each deal you discover be a rewarding moment in your shopping journey.`,
  },

];
function Slideshow() {
  const [index, setIndex] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNext = () => {
    setIndex((prevIndex) => prevIndex + 1);
  };

  const handleBack = () => {
    setIndex((prevIndex) => prevIndex - 1);
  };

  return (
    <Box sx={{ maxWidth: 800, flexGrow: 1, m: 'auto', p: isSmallScreen ? 2 : 4 }}>
      <Paper square elevation={0} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 50, pl: 2, pr: 2, bgcolor: 'transparent' }}>
        <Typography color="white" variant="h4" textAlign="center">{steps[index].label}</Typography>
      </Paper>

      <SwipeableViews index={index} onChangeIndex={setIndex} enableMouseEvents>
        {steps.map((step, index) => (
          <Box key={index} sx={{ height: 355, maxWidth: 1000, p: 2 }}>
            <Typography variant="h6">{step.description}</Typography>
          </Box>
        ))}
      </SwipeableViews>

      <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
        <Button size="large" disabled={index === 0} onClick={handleBack} startIcon={<ArrowBackIcon />} sx={{ color: index === 0 ? 'grey' : '#FFA861' }}>
          Back
        </Button>
        <Button size="large" disabled={index === steps.length - 1} onClick={handleNext} endIcon={<ArrowForwardIcon />} sx={{ color: index === steps.length - 1 ? 'grey' : '#FFA861' }}>
          Next
        </Button>
      </Grid>
    </Box>
  );
}

export default Slideshow;

