import minion from "../src/Assets/minion.jpeg";

const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

const dEvents = [
  {
    name: "Another Event Example: Coding Workshop",
    summary: "Learn the basics of web development in this interactive workshop.",
    attendance_max: 30,
    proposal: false,
    rejected: false,
    is_removed: false,
    start_datetime: "2024-03-11T09:30:00.000Z",
    end_datetime: "2024-03-11T12:00:00.000Z",
    org_id: "78901",
    latitude: 53.5547, // Adjusted latitude slightly
    longitude: -113.4908 // Adjusted longitude slightly
  },
  {
    name: "Public Lecture on Artificial Intelligence",
    summary: "A renowned expert discusses the future of AI and its impact on society.",
    attendance_max: 200,
    proposal: true,
    rejected: false,
    is_removed: false,
    start_datetime: "2024-03-12T18:00:00.000Z",
    end_datetime: "2024-03-12T20:00:00.000Z",
    org_id: "34567",
    latitude: 53.5410, // Adjusted latitude slightly
    longitude: -113.4863 // Adjusted longitude slightly
  },
  {
    name: "Board Game Night: Bring Your Favorites!",
    summary: "Join us for an evening of friendly competition and board games.",
    attendance_max: 40,
    proposal: false,
    rejected: false,
    is_removed: false,
    start_datetime: "2024-03-15T19:00:00.000Z",
    end_datetime: "2024-03-15T22:00:00.000Z",
    org_id: "87644",
    latitude: 53.5372, // Adjusted latitude slightly
    longitude: -113.4954 // Adjusted longitude slightly
  },
  {
    name: "Local Food Festival",
    summary: "Explore local cuisine from top food trucks and vendors.",
    attendance_max: 150,
    proposal: false,
    rejected: false,
    is_removed: false,
    start_datetime: "2024-04-20T11:00:00.000Z",
    end_datetime: "2024-04-20T17:00:00.000Z",
    org_id: "10234",
    latitude: 53.5409,
    longitude: -113.4923
  },
  {
    name: "Live Music Night: Indie Bands",
    summary: "Enjoy a night of indie music with live performances from emerging bands.",
    attendance_max: 100,
    proposal: true,
    rejected: false,
    is_removed: false,
    start_datetime: "2024-04-25T19:00:00.000Z",
    end_datetime: "2024-04-25T23:00:00.000Z",
    org_id: "20485",
    latitude: 53.5462,
    longitude: -113.4860
  },
  {
    name: "Art & Wine Walk",
    summary: "A leisurely stroll through local galleries with wine tasting stations along the way.",
    attendance_max: 80,
    proposal: false,
    rejected: false,
    is_removed: false,
    start_datetime: "2024-05-05T15:00:00.000Z",
    end_datetime: "2024-05-05T19:00:00.000Z",
    org_id: "30876",
    latitude: 53.5438,
    longitude: -113.4882
  },
  {
    name: "Outdoor Yoga Session",
    summary: "Join us for a rejuvenating outdoor yoga session in the park. All levels welcome.",
    attendance_max: 50,
    proposal: true,
    rejected: false,
    is_removed: false,
    start_datetime: "2024-05-12T09:00:00.000Z",
    end_datetime: "2024-05-12T10:30:00.000Z",
    org_id: "41267",
    latitude: 53.5411,
    longitude: -113.4931
  },
  {
    name: "Tech Talk: Future of AI",
    summary: "Industry experts discuss the future of artificial intelligence and machine learning.",
    attendance_max: 200,
    proposal: false,
    rejected: false,
    is_removed: false,
    start_datetime: "2024-05-18T18:00:00.000Z",
    end_datetime: "2024-05-18T20:00:00.000Z",
    org_id: "52698",
    latitude: 53.5374,
    longitude: -113.5065
  },
  {
    name: "Community Clean-Up Day",
    summary: "Help beautify our community in this volunteer clean-up event. Supplies provided.",
    attendance_max: 100,
    proposal: false,
    rejected: false,
    is_removed: false,
    start_datetime: "2024-06-01T08:00:00.000Z",
    end_datetime: "2024-06-01T12:00:00.000Z",
    org_id: "63029",
    latitude: 53.5444,
    longitude: -113.4909
  },
  {
    name: "Marathon for Charity",
    summary: "Run or walk in our charity marathon. Proceeds go to local charities.",
    attendance_max: 300,
    proposal: false,
    rejected: false,
    is_removed: false,
    start_datetime: "2024-06-15T07:00:00.000Z",
    end_datetime: "2024-06-15T11:00:00.000Z",
    org_id: "73410",
    latitude: 53.5461,
    longitude: -113.4937
  }
  
];

export { reportWebVitals, dEvents };