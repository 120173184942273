import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(20),
  },
  paper: {
    padding: theme.spacing(6),
    textAlign: 'center',
    maxWidth: '800px',
    height: '400px', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: '200px', 
 
  },
}));

const CheckoutSuccessPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3} justifyContent="center" >
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper} background={'#f5f5f5'} >
            <Typography variant="h3" fontFamily={'cabin'} gutterBottom color={'black'}>
              Order Confirmed
            </Typography>
            <Typography variant="body1" fontFamily={'cabin'} gutterBottom color={'black'}>
              Thank you for your order! We will notify you when your account is verifed.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              href="/"
            >
             Home
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CheckoutSuccessPage;


