import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Box,
    Card,
    CardContent,
    CardMedia,
    Container,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputAdornment,
    TextField,
    Toolbar,
    Typography,
    IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import uniOneLogo from '../src/Assets/uniONE-Logo.png';
import Footer from './components/Footer';
import Cookies from 'js-cookie';
import { CookieNames, backend_url } from './constants';
import { cookie } from './utils';


// individual container for each organization 
// where an organization is either a club or business partner
const OrganizationBox = ({ name, image, onClick }) => {
    // onclick will be applied to the whole Card
    const [isHovered, setIsHovered] = useState(false); // State for hover effect
    const defaultImage = uniOneLogo; // default logo for organizations

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
  
    const cardStyles = {
        ...(isHovered && {
          transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out', // Set transition for smoother effects
          transform: 'translateY(-5px)', // Lift on hover
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', // Add subtle shadow on hover
          border: `1px solid #EF7B54`, // Set hover border color with theme access
        }),
      };
  return (
    <Card sx={{ display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      justifyContent: 'start',
      maxWidth: 345,
      margin: 'auto',
      marginBottom: 2,
      cursor: 'pointer',
      ...cardStyles }}
       onMouseEnter={handleMouseEnter}
       onMouseLeave={handleMouseLeave}
       onClick={onClick}>
      <CardMedia
        component="img"
        height="140"
        image={image ? image : defaultImage} // Use logo if available, otherwise use default
        alt="Organization logo"
        sx={{
            display: 'inline-block',
            maxWidth: '33%',
            height: 'min-content',
            margin: '1rem 0rem .25rem',
        }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
          {name}
        </Typography>
      </CardContent>
    </Card>
  );
};

// Pop up modal for when an organization is clicked
const OrganizationModal = ({ open, onClose, organization }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{organization.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" component="div">
                Email: {organization.email}
            </Typography>
            <Typography variant="body1" component="div">
                Phone: {organization.phone}
            </Typography>
            <Typography variant="body1" component="div">
                Creation Date: {organization.creationDate}
            </Typography>
            <Typography variant="body1" component="div">
                Approved: {organization ? (organization.pending ? 'No' : 'Yes') : 'No'}
            </Typography>
            <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-wrap' }}>
                Description: {organization.description}
            </Typography>
          </DialogContentText>
        </DialogContent>
        {/* ... other modal content */}
      </Dialog>
    );
  };
  

const Organizations = () => {
  const [initialOrganizations, setInitialOrganizations] = useState([]) // all organizations
  const [organizations, setOrganizations] = useState([]); // temporaily set organizations to test data
  const [open, setOpen] = useState(false); // is modal open or not
  const [selectedOrganization, setSelectedOrganization] = useState({}); // org that user clicks on
  const [noResultsFound, setNoResultsFound] = useState(false); // State for no results

  const fetchData = async () => {
    const authToken = Cookies.get('accessToken') || '';
    const options = {
        headers: {
        'Authorization': `Bearer ${authToken}`
        }
    }
    const response = await fetch(backend_url + `/organizations/organization?&rejected=False`, options);
    
    // if request was successful
    if (response.status === 200) {
        // get data from the response and set organizations
        // uncomment later after testing with fake data
        const data  = await response.json();
        setOrganizations(data.map((org) => ({
           name: org.name,
           email: org.email,
           phone: org.phone,
           creationDate: org.creationDate,
           pending: org.pending, // Assuming a "pending" property for approval
           description: org.description,
        })));
    }
  };

  useEffect(() => {
    fetchData(); // hit API and get related clubs and businesses

  },[]);

  const handleBoxClick = (organization) => { // handle click for when user clicks on an organization
    setSelectedOrganization(organization);
    setOpen(true);
  };

  const handleModalClose = () => { // close modal
    setOpen(false);
  };

  return (
    <>
      {/* <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6" component="div" sx={{ margin: 'auto', color: 'white', my: 2}}>
            View Organizations
          </Typography>
        </Toolbar>
      </AppBar> */}
      <Box bgcolor="#f5f5f5" color="#000" minHeight="100px">
        {/* Promotions header section */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="800px"
          bgcolor="#272723"
          boxShadow={3}
          sx={{ margin: 'auto', marginBottom: '20px', width: '100%', textAlign: 'center' }}
        >
          <Typography
            variant="h3"
            sx={{
              padding: '10px',
              borderRadius: '5px',
              color: 'white',
              fontWeight: 'bold',
              fontFamily: '"Cabin", sans-serif',
            }}
          >
          Organizations
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="lg">
        {noResultsFound && ( // style for displaying when nothing is returned in the search.
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100px',
              opacity: 0.5, // Set a slight opacity
            }}
          >
            <ErrorOutlineIcon style={{ fontSize: '40px', color: '#f44336' }} />
            <Typography variant="h6" component="div" sx={{ marginLeft: 1 }}>
              Sorry, we couldn't find what you're looking for...
            </Typography>
          </Box>
        )}
        <Grid container spacing={2} justifyContent="center">
          {organizations.map((organization) => (
            <Grid item xs={12} sm={4} md={3} key={organization.id}>
              <OrganizationBox
                name={organization.name}
                image={organization.logo} // possible logo placement from API
                onClick={() => handleBoxClick(organization)}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <OrganizationModal open={open} onClose={handleModalClose} organization={selectedOrganization} />
      {/* Footer section for additional navigation or information */}
      <Grid style={{ marginTop: '200px', width:'100%' }}>
        <Footer />
      </Grid>
    </>
  );
};

export default Organizations;