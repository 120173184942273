import { Box, SvgIcon, TextField, Button, Typography, Grid, Link } from '@mui/material';
import UnioneLogo from './Logo';
import Wordmark from './Wordmark/Wordmark';
import { useState } from 'react';
import EastIcon from '@mui/icons-material/East';
import Cookies from 'js-cookie';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Error states for the input fields
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [badInput, setBadInput] = useState(false);

  const emailRegex = /.+@.+\..+/;

  const handleSubmit = async (event) => {
    event.preventDefault();

    setEmailError(!email || !emailRegex.test(email));
    setPasswordError(!password);

    const isInputValid = email && password && emailRegex.test(email);

    if (isInputValid) {
        const payload = {
            email: email,
            password: password,
        };

        try {
            const response = await fetch('https://api.unione.cc/logins/login?type=student', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            const responseData = await response.json();

            if (response.ok) {
                console.log("Login successful!");

                // Set tokens in cookies instead of localStorage
                Cookies.set('accessToken', responseData.access, { expires: 1/8 }); 
                Cookies.set('refreshToken', responseData.refresh, { expires: 50 });                
                Cookies.set('userType', 'student', { expires: 50 }); // New cookie to differentiate user types


                // Redirect or perform other actions upon successful login
                window.location.href = '/';
            } else {
                console.error("Login failed:", responseData.detail || "Unknown error");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    } else {
        setBadInput(true);
    }
};


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        bgcolor: '#272723',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '5px',
          paddingX: { xs: 5, sm: 10 },
          paddingY: { xs: 5, sm: 10 },
          marginX: 2,
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SvgIcon component={UnioneLogo} width='4rem' height='4rem'/>
          <SvgIcon component={Wordmark} width='12.5rem' height='3rem' />
        </Box>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Typography color="error" fontWeight={600} display={badInput ? "" : "none"}>Invalid or missing inputs</Typography>
          <Grid container spacing={{ xs: 2 }} maxWidth='sm'>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                type='email'
                onChange={e => setEmail(e.target.value)}
                error={emailError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => setPassword(e.target.value)}
                error={passwordError}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, color: "#FFFFFF", bgcolor: "#EF7B54", ":hover": { bgcolor: "#FFFFFF", color: '#EF7B54' }}}
            endIcon={<EastIcon />}
          >
            Login
          </Button>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography fontWeight={600}>Don't have an account? <Link href="/signup" sx={{ color: "#FAB278", textDecoration: 'none', "&:hover": { color:"#EF7B54" }}}>Sign Up</Link></Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
