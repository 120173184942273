import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import uniOneLogo from '../../src/Assets/uniONE-Logo.png'
import LanguageIcon from '@mui/icons-material/Language';

export default function Footer({ textColor = 'text.primary' }) {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 'xl',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}>
          <Box sx={{ width: { xs: '80%', sm: '60%' } }}>
            <Box sx={{ ml: '-15px' }}>
              <img
                src={uniOneLogo}
                style={{ width: '60px', height: 'auto' }}
                alt="logo of uniONE"
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}>
          <Typography variant="body2" fontWeight={600} color={textColor}>
            uniONE
          </Typography>
          <Link color={textColor} href="/about">
            About
          </Link>
          <Link color={textColor} href="/pricing">
            Subscribe
          </Link>
          <Link color={textColor} href="/team">
            Team
          </Link>
          {/* <Link color={textColor} href="#">
            Investors
          </Link> */}
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}>
          <Typography variant="body2" fontWeight={600} color={textColor}>
            Support
          </Typography>
          {/* <Link color={textColor} href="#">
            Help center
          </Link> */}
          <Link color={textColor} href="mailto:hello@unione.cc">
            Contact form
          </Link>
          <Link color={textColor} href="https://docs.google.com/forms/u/0/d/1yr5zax-b_R-acB03isUoZosIHvF4u4XlaozoVrLVIgw/edit?pli=1">
            Feedback form
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}>
          <Typography variant="body2" fontWeight={600} color={textColor}>
            Products
          </Typography>
          <Link color={textColor} href="https://docs.google.com/forms/u/0/d/1yr5zax-b_R-acB03isUoZosIHvF4u4XlaozoVrLVIgw/edit?pli=1">
            App
          </Link>
          <Link color={textColor} href="https://dashboard.unione.cc/">
            Dashboard
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
          color: textColor
        }}>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '-5px', color: textColor }}>

          <LanguageIcon />



          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5, }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link href="#" color={textColor}>
            Terms of Service
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link href="#" color={textColor}>
            Privacy Policy
          </Link>

        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
          }}>
          <IconButton
            color={textColor}
            href="https://twitter.com/uniONEapp"
            aria-label="X"
            sx={{ alignSelf: 'center', color: textColor }}>
            <TwitterIcon />
          </IconButton>
          <IconButton
            color={textColor}
            href="https://ca.linkedin.com/company/unione-ca"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center', color: textColor }}>
            <LinkedInIcon />
          </IconButton>
          <IconButton
            color={textColor}
            href="https://www.instagram.com/unioneapp/?igsh=MW9sd213MXFwNG8xZA%3D%3D&utm_source=qr"
            aria-label="Instagram"
            sx={{ alignSelf: 'center', color: textColor }}>
            <InstagramIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}