// Importing necessary React, Material-UI components, custom components, and other utilities
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import Footer from './components/Footer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import dashboardimg from '../src/Assets/dashboard.png'; // Importing an image for use in the UI
// The use of useState and useEffect hooks from React are commented out as they're not used in this snippet.
// import { useState, useEffect } from 'react';
import './subscription.css'; // Import CSS for styling the subscription component
import DoneIcon from '@mui/icons-material/Done';
// import CheckoutForm from '../src/CheckoutForm'; // This commented import suggests there might be a checkout form component not included in the snippet.
import axios from 'axios'; // Importing axios for making HTTP requests
import { cookie } from './utils';
import { CookieNames } from './constants';
import { checkTokenExpiration } from './utils';
// The Subscription functional component starts here
function Subscription() {
    const theme = useTheme(); // Using the theme provided by Material-UI for consistent styling
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Checking if the viewport width is small (mobile device)
    
    useEffect(() => {
        checkTokenExpiration();
        console.log(cookie.get(CookieNames.AUTH_TOKEN));
    }, [])

    const subscriptionPlans = [
        {
            title: "Weekly",
            price: "$3.99",
            period: "/week",
            description: "Ideal for short-term projects and events and trying out our features",
            features: [
                "Access to all features",
                "Dashboard Access",
                "Email support",
                "Help center access",
            ],
            buttonText: "Select Plan"
        },
        {
            title: "Monthly",
            price: "$30",
            period: "/month",
            description: "A short-term plan perfect for trying out our features.",
            features: [
                "Access to all features",
                "Dashboard Access",
                "Email support",
                "Help center access",
            ],
            buttonText: "Select Plan"
        },
        {
            title: "Yearly",
            price: "$60",
            period: "/year",
            description: "Our best value plan with a discount for long-term use.",
            features: [
                "Access to all features",
                "Dashboard Access",
                "Priority email support",
                "Dedicated help center access",
            ],
            buttonText: "Select Plan"
        }
    ];

    const planPrices = {
        Monthly: '30.00',
        Yearly: '60.00',
        Weekly: '3.99',
    }

     const handleSelectPlan = async (planType) => {
        try {
           const myCookie = cookie.get(CookieNames.AUTH_TOKEN)
           const orgId = cookie.get(CookieNames.ORG_ID);
           console.log(orgId, myCookie)

            if (myCookie === undefined || myCookie=== null) {
         
                window.location.href = 'https://dashboard.unione.cc/sign-in-org';
                return;
            } 

            // Assume this function handles the subscription logic and eventually navigates to the payment page
            const { data } = await axios.post(`https://api.unione.cc/create-checkout-session?org_id=${orgId}`, { planType, myCookie });
            
            const subscriptionPrice = parseFloat(planPrices[planType])

            if (!subscriptionPrice) {
                console.error('Invalid plan type:', planType);
                return; // Exit the function if the plan type is invalid
            }

            const transactionId = data.sessionId;

            // Track the subscription purchase event
            window.gtag('event', 'purchase', {
              transaction_id: transactionId,
              value: subscriptionPrice,
              currency: 'USD',
              items: [{ planType }],
              business_type: 'Business User Type' // Collect the business user type information based on actual situations
            });
    
            window.location.href = data.url;
        } catch (error) {
            console.error('Error redirecting to checkout:', error);
        }
    };
 // Render the subscription UI
return (
        <Box style={{ backgroundColor: 'white' }}>
            <Container maxWidth="lg" sx={{ py: 5 }}>
                {/* Subscription header */}
                <Box textAlign="center" p={4} bgcolor="white" boxShadow={1} borderRadius={2}>
                    <Typography variant="h2" color="textPrimary" gutterBottom>
                        Take Control of Your Event Planning
                    </Typography>
                    <Typography variant="h5" color="textSecondary" paragraph>
                        Discover the perfect subscription plan for your needs with uniONE.
                    </Typography>
                    <Grid container spacing={4} justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={6}>
                            <img src={dashboardimg} alt="Dashboard" style={{ maxWidth: '100%', height: 'auto' }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {['Create new events', 'Manage event attendees', 'Promote deals or discounts', 'Connect with universities and students'].map((feature, index) => (
                                <Box key={index} my={1} display="flex" alignItems="center">
                                    <DoneIcon sx={{ color: theme.palette.primary.main }} />
                                    <Typography ml={1}>{feature}</Typography>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
             {/* Subscription plans grid */}
            <Container maxWidth="lg" component="main">
                <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Subscription Plans
                </Typography>
                <Grid container spacing={isMobile ? 2 : 4} justifyContent="center">
                    {subscriptionPlans.map((plan, index) => (
                        <Grid item key={index} xs={12} sm={plan.title === "Monthly" ? 12 : 6} md={4}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                p={isMobile ? 2 : 4}
                                mx={1}
                                my={3}
                                border={2}
                                borderColor={plan.title === "Monthly" ? "primary.main" : "grey.300"}
                                borderRadius={2}
                                className={`subscription-plan ${plan.title === "Monthly" ? "highlighted-plan" : ""}`}
                                sx={{ transform: plan.title === "Monthly" ? "scale(1.05)" : "none", transition: "transform .3s" }}
                            >
                                <Typography component="h4" variant="h5" color="textPrimary" gutterBottom>
                                    {plan.title}
                                </Typography>
                                <Typography component="h2" variant="h3" color="primary" gutterBottom>
                                    {plan.price}
                                    <Typography component="span" variant="h6" color="textSecondary">
                                        {plan.period}
                                    </Typography>
                                </Typography>
                                <Typography variant="subtitle1" align="center" gutterBottom>
                                    {plan.description}
                                </Typography>
                                <Box>
                                    {plan.features.map((feature) => (
                                        <Box key={feature} display="flex" alignItems="center" my={1}>
                                            <DoneIcon color="primary" />
                                            <Typography variant="subtitle1" marginLeft={1}>
                                                {feature}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                                <Button
                                    fullWidth
                                    variant={plan.title === "Monthly Plan" ? "contained" : "outlined"}
                                    color="primary"
                                    size="large"
                                    onClick={() => handleSelectPlan(plan.title)}
                                    sx={{
                                        mt: 3,
                                        bgcolor: plan.title === "Monthly" ? "primary.main" : "white", // Initial background color based on plan
                                        color: plan.title === "Monthly" ? "white" : "primary.main", // Initial text color based on plan
                                        '&:hover': {
                                            bgcolor: plan.title === "Monthly" ? "white" : "white", // Keep background color on hover for Monthly Plan
                                            color: plan.title === "Monthly" ? "primary.main" : "primary.main", // Change text color on hover to #F5F5F5 for Monthly Plan
                                        },
                                    }}
                                >
                                    {plan.buttonText}
                                </Button>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
    
            <Footer />
        </Box>
    );
}


export default Subscription;

