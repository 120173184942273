import Cookie from 'universal-cookie';
import { CookieNames } from './constants';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie'

/**
 * Set cookie values in the same path
 */
function setCookie(key, value, options) {
    const cookie = new Cookie();
  
    cookie.set(key, value, {
      secure: true,
      sameSite: 'strict',
      path: '/',
      ...options
    });
  }
  
  /**
   * Get cookie by key
   */
  function getCookie(key) {
    const cookie = new Cookie();
  
    return cookie.get(key);
  }
  
  /**
   * Remove a cookie value by id
   */
  function removeCookie(key, options) {
    const cookie = new Cookie();
  
    cookie.remove(key, options);
  }
  
  export const cookie = {
    set: setCookie,
    get: getCookie,
    remove: removeCookie
  };
  /**
   * Check the expiration date of auth token and remove the cookie if expired
   */
  export function checkTokenExpiration(key) {
    const authToken = cookie.get(CookieNames.AUTH_TOKEN);
    const stuauthToken = Cookies.get('accessToken');
    
    // Is there a valid auth token?
    if (!authToken && !stuauthToken) {
      return;
    }
    if (stuauthToken) {
      const decodedToken = jwtDecode(stuauthToken);
      const currentTime = Date.now() / 1000;
      if (currentTime >= decodedToken.exp) {
        Cookies.remove('accessToken');
        window.location.reload();
      }
    }
    // Decode the token
    if (authToken) {
      const decodedToken = jwtDecode(authToken);
  
      // Get current time in seconds
      const currentTime = Date.now() / 1000;
  
      // Did the token expire?
      if (currentTime >= decodedToken.exp) {
        // Remove the cookie and refresh the page
        cookie.remove(CookieNames.AUTH_TOKEN, { path: '/' });
        window.location.reload();
      }
    }
  }