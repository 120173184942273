import React, { useState, useEffect } from 'react';
import Footer from './components/Footer';
import { Box, Typography, Grid, Modal, Button } from '@mui/material';
import { dAds } from './reportWebVitals';
import { backend_url, CookieNames } from './constants'; 
import Cookies from 'js-cookie';


// This test is linked to US 05.01
function Ads() {
    const [adsData, setAdsData] = useState([]);
    const [openModal, setOpenModal] = useState([]);

    useEffect(() => {
        async function fetchAds() {
            const authToken = Cookies.get('accessToken') || '';
            console.log(authToken);
            const options = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                }
            };
            try {
                const response = await fetch(`${backend_url}/ads/ad?&rejected=False`, options);
                if (response.ok) {
                    const data = await response.json();
                    setAdsData(data);
                    setOpenModal(Array(data.length).fill(false));
                } else {
                    console.error('Failed to fetch ads:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching ads:', error);
            }
        }

        fetchAds();
    }, []);

    const handleOpenModal = (index) => {
        let updatedOpenModal = [...openModal];
        updatedOpenModal[index] = true;
        setOpenModal(updatedOpenModal);
    };

    const handleCloseModal = (index) => {
        let updatedOpenModal = [...openModal];
        updatedOpenModal[index] = false;
        setOpenModal(updatedOpenModal);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        maxWidth: '600px',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
    };
    return (
        <Box bgcolor="#f5f5f5" color="#000" minHeight="100px">
            {/* Promotions Header */}
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="800px"
                bgcolor="#272723"
                boxShadow={3}
                sx={{ margin: 'auto', marginBottom: '20px', width: '100%', textAlign: 'center' }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        padding: '10px',
                        borderRadius: '5px',
                        color: 'white',
                        fontWeight: 'bold',
                        fontFamily: '"Cabin", sans-serif'
                    }}
                >
                    Promotions
                </Typography>
            </Box>

            {/* Ads Display Grid */}
            <Grid container spacing={2} justifyContent="center" sx={{ padding: '0 10px' }}>
                {adsData.map((ad, index) => (
                    <Grid item xs={12} sm={12} md={6} lg={4} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                            sx={{
                                minHeight: '400px',
                                bgcolor: '#ffffff',
                                boxShadow: '6px 6px 10px rgba(255, 165, 0, 0.8)',
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 6,
                                width: '550px',
                                mx: 'auto',
                                marginTop: '80px',
                                marginBottom: '20px',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleOpenModal(index)}
                        >
                            {/* Ad Name */}
                            <Typography fontWeight="bold" variant="h5" sx={{ fontFamily: 'Cabin', marginBottom: 3, color: '#000', fontSize: '1.5rem' }}>
                                {ad.name}
                            </Typography>
                            {/* Ad Description */}
                            <Typography variant="body1" sx={{ fontFamily: 'Cabin', color: '#000', fontSize: '1.25rem' }}>
                                {ad.description}
                            </Typography>
                        </Box>
                        {/* Modal for Ad Details */}

                        <Modal
                            open={openModal[index]}
                            onClose={() => handleCloseModal(index)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={modalStyle}>
                                <Box
                                    component="img"
                                    sx={{
                                        height: 'auto',
                                        width: '100%',
                                        maxHeight: '300px',
                                        objectFit: 'cover',
                                    }}
                                    alt="Promotional content"
                                    src={ad.media}
                                />
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontSize: '1.75rem' }}>
                                    {ad.name} Details
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: '1.25rem' }}>
                                    <strong>Start:</strong> {ad.startTime}<br />
                                    <strong>End:</strong> {ad.endTime}<br />
                                    <strong>Description:</strong> {ad.description}<br />
                                    <strong>Promo Code:</strong> {ad.promoCode}
                                </Typography>
                                <Button onClick={() => handleCloseModal(index)} sx={{ mt: 2, fontSize: '1rem' }}>
                                    Close
                                </Button>
                            </Box>
                        </Modal>
                    </Grid>
                ))}
            </Grid>

            <div style={{ marginTop: '200px', width: '100%' }}>
                <Footer textColor="common.black" />
            </div>
        </Box>
    );
}

export default Ads;