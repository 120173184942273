// useAuth.js
import { useState, useEffect } from 'react';
import { cookie } from '../../utils';
import { CookieNames } from '../../constants';
import Cookies from 'js-cookie'; 

const useAuth = () => {
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const businessAuthToken = cookie.get(CookieNames.AUTH_TOKEN);
    const isStudentAuthenticated = Cookies.get('userType');;

    if (businessAuthToken) {
      setUserType('business');
    } else if (isStudentAuthenticated) {
      setUserType('student');
    } else {
      setUserType(null);
    }
  }, []);

  return userType;
};

export default useAuth;
