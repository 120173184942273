import React, { useState, useEffect } from 'react';
import { GoogleMap, useLoadScript, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { useTheme, useMediaQuery, Typography, Card, CardActionArea, CardContent, Box, Grid } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import './events.css';
import Footer from './components/Footer';
import { parseISO, format } from 'date-fns';
import Cookies from 'js-cookie';
import { CookieNames, backend_url } from './constants';
import { cookie } from './utils';


const API_KEY = 'AIzaSyCff2ncXKs7vghoPdvW-s9bsSZriHOmzGw' // change this with your actual API_KEY and probably best not to keep it hard-coded in
  
const collapseIconStyles = {
    marginRight: '10px',
    transition: 'transform 0.3s ease-in-out',
};


function Events() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const center = { lat: 53.5461, lng: -113.4937 };

    const [map, setMap] = useState('')
    const [searchText, setSearchText] = useState(''); // Search term for location
    const [events, setEvents] = useState(); // Events to be populated
    const [currentEvents, setCurrentEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const [showEventsList, setShowEventsList] = useState(true); // State for dropdown visibility on current events
    const [showPastEventsList, setShowPastEventsList] = useState(true); // State for dropdown visibility on previous events
    const [mapCenter, setMapCenter] = useState(center); // Initial map center
    const [zoom, setZoom] = useState(10); // Initial zoom level
    const [activeMarker, setActiveMarker] = useState(null); // keeps track of which pin icon is covered over
    const [searchBar, setSearchBar] = useState(null);

    const { isLoaded, loadError } = useLoadScript({ // loading google map
        googleMapsApiKey: API_KEY, // Google API will need to swap out
        libraries: ['places'], // places library for searching functionality
    });

    const mapContainerStyle = { // styling for google maps and didn't work if it was in css file
      width: isMobile ? '80vw' : '50vw',
      height: '90vh',
      float: isMobile ? '' : 'right',
      borderRadius: '10px', // Add border radius for curved edges
      margin: isMobile ? 'auto' : '10px', // Add margin for spacing
    };

    const onMapLoad = (map) => {
        setMap(map);
    };

    const onSearchBarLoad = (searchBar) => {
        setSearchBar(searchBar);
    };

    const onPlacesChanged = () => {
        const places = searchBar.getPlaces();
        const place = places[0];
        const location = {
          lat: place?.geometry?.location?.lat() || 0,
          lng: place?.geometry?.location?.lng() || 0,
        };
        map?.panTo(location);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const toggleEventsList = () => {
        setShowEventsList(!showEventsList); // Toggle dropdown visibility
    };

    const formatDate = (dateString) => {
        const parsedDate = parseISO(dateString);
        return format(parsedDate, 'yyyy-MM-dd'); // Adjust format as needed
    };

    const togglePastEventsList = () => {
      setShowPastEventsList(!showPastEventsList); // Toggle dropdown visibility
    };
      
    const formatTime = (dateTimeString) => {
      // Extract time portion and timezone information (including offset)
      const [timeString, timezoneInfo] = dateTimeString.slice(11).split('Z');

      // Extract offset from timezone information (if present)
      const offset = timezoneInfo ? timezoneInfo : 'Z'; // Assign 'Z' if no offset

      // Convert to 12-hour clock format
      const [hours, minutes] = timeString.split(':');
      let standardHours = parseInt(hours) % 12;
      standardHours = standardHours === 0 ? 12 : standardHours;
      const amPm = parseInt(hours) < 12 ? 'AM' : 'PM';

      // Determine timezone string
      const timezoneString = offset === 'Z' ? 'UTC' : `GMT${offset}`;

      // Return formatted time string with timezone string and offset
      return `${standardHours}:${minutes}${amPm} ${timezoneString} (${offset})`;
    };


    const handleEventClick = (eventData) => {

      // Set the map center to the clicked event's latitude and longitude
      setMapCenter({ lat: eventData.latitude, lng: eventData.longitude });
      // Optionally, adjust zoom level for better focus on the event
      setZoom(15); // Adjust zoom level as needed
      setActiveMarker(eventData);


      window.gtag('event', 'event_click', {
        event_name:eventData.name,
        org_id:eventData.org_id,
      });

    };

    const handleKeyPress = (event) => {
      // Check if the pressed key is Enter key (key code 13)
      if (event.key === 'Enter') {
        // Call function to handle event click
        console.log("What is eventData",event);
        handleEventClick(event);
      }
    };

    // const handleResetMap = () => {
    //     setMapCenter(center); // Reset to original center
    //     setZoom(10);
    //     setActiveMarker(null);
    // };
    
    useEffect(() => {
      async function fetchData() {
        const authToken = Cookies.get('accessToken') || '';
        const options = {
          method: 'GET', 
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json' 
          }
        };
    
        try {
          const response = await fetch(`${backend_url}/events/event?&rejected=False`, options);
          if (!response.ok) {
            throw new Error(`Error: ${response.status}`); 
          }
          const data = await response.json();
          setEvents(data); // Assuming you want to keep a copy of all events
          setCurrentEvents(data); // Directly use the fetched data
        } catch (error) {
          console.error('Fetching events failed:', error);
        }
      }
    
      fetchData();
    }, []); // Empty dependency array means this effect only runs once on mount
    


    const renderCurrentEvents = (currentEvents) => {
        return currentEvents.map((event) => (
          <Card key={event.org_id} sx={{ mb: 2 }} elevation={2}>
            <CardActionArea onClick={() => handleEventClick(event)}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {event.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {event.summary}
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  When: {formatDate(event.start_datetime)} at {formatTime(event.start_datetime)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ));
    };
    

  // const renderPastEvents = (pastEvents) => {
  //   return pastEvents.map((event) => (
  //     <div className="event-container" key={event.org_id} onClick={() => handleEventClick(event)} onKeyDown={(e) => handleKeyPress(e, event)} tabIndex={0}>
  //         <h2>{event.name}</h2>
  //         {event.org_email && <h3>Hosted by: {event.org_email}</h3>}
  //         <p>{event.summary}</p>
  //         {/* Formatted date and time */}
  //         <Box 
  //           sx={{
  //             mb: 2,
  //           }}
  //         >
  //           <Typography
  //             sx={{
  //                 m: '.5rem auto .15rem',
  //             }}
  //           >
  //             When is it happening?
  //           </Typography>
  //           On ({formatDate(event.start_datetime)}) at {formatTime(event.start_datetime)}
  //         </Box>
  //       </div>
  //     ));
  //   };

    // const renderMap = () => {
    //   return (
    //   // Main google map component
    //   <GoogleMap 
    //     onLoad={onMapLoad} // update function
    //     mapContainerStyle={mapContainerStyle} // call styles present in js (doesn't work if in .css)
    //     zoom={zoom} // zoom variable to control default zoom in
    //     center={mapCenter}
    //     options={{
    //       mapTypeControl: false, // disable terrain and satellite views
    //       draggable: true, // let users move map around
    //     }}
    //     >
    //     {currentEvents && currentEvents.map((event) => (
    //       <MarkerF
    //           key={event.org_id}
    //           position={{ lat: event.latitude, lng: event.longitude }}
    //           onClick={()=> {
    //               setActiveMarker(event)
    //               setZoom(15)
    //           }}
    //       /> 
    //       ))}
    //       {activeMarker && (
    //           <InfoWindowF
    //               position={{ lat: activeMarker.latitude, lng: activeMarker.longitude }}
    //               onCloseClick={() => setActiveMarker(null)}
    //           >
    //               <div>{activeMarker.name}</div>
    //           </InfoWindowF>
    //       )
    //     }
    //   </GoogleMap>
    //   );
    // };

    // if (loadError) return 'Error loading maps';
    // if (!isLoaded) return <div>Loading...</div>;

    return (
      
        <div className="App">
          
          <Box
            className="header-container"
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="800px"
          bgcolor="#272723"
          boxShadow={3}
            sx={{
              margin: 'auto', marginBottom: '20px', width: '100%', textAlign: 'center'
            }}
          >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h3' sx={{
              padding: '10px',
              borderRadius: '5px',
              color: 'white',
              fontWeight: 'bold',
              fontFamily: '"Cabin", sans-serif',
              ml:"1rem",
              mr:"1rem",
            }}>
              Events
            </Typography>
            <Typography variant='h4' sx={{
              padding: '10px',
              borderRadius: '5px',
              color: 'white',
              fontWeight: 'bold',
              fontFamily: '"Cabin", sans-serif',
              ml:"1rem",
              mr:"1rem",
            }}>
              Curious on what's happening in your area?
            </Typography>
            <Typography variant='h5' sx={{
              padding: '10px',
              borderRadius: '5px',
              color: 'white',
              fontWeight: 'bold',
              fontFamily: '"Cabin", sans-serif',
              ml: "1rem",
              mr: "1rem",
            }}>
              Check local events and see what interests you!
            </Typography>
          </Box>
          </Box>
          
        
        <Box className='content-container'
          display='flex'
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent='space-between'
        >
          <Box className='events-container' // Main container that holds both current and past events
            sx={{
              ml: '.5rem',
              mt: '1rem',
              mr: '1rem',
              height: isMobile ? 'auto' : '90vh',
              width: isMobile ? '95%' : '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {/* <Typography  sx={{ fontFamily: '"Cabin", sans-serif', textAlign: 'center', fontSize: "2rem" }}>Events</Typography> */}
            <div className="current-event-list-container" style={{ display: 'flex', flexDirection: 'column', margin: '0 1rem .5rem', width: isMobile ? '95%' : '100%', overflowY: 'scroll'}}>             
              
              {/* Render current events without Collapse */}
              {currentEvents && renderCurrentEvents(currentEvents)}
            </div>
            {/* <Typography sx={{ fontFamily: '"Cabin", sans-serif', textAlign: 'center', fontSize: "2rem" }}>Past Events</Typography>
            <div className="past-event-list-container" style={{ display: 'flex', flexDirection: 'column', margin: '0 1rem', width: isMobile ? '95%' : '100%', overflowY: 'scroll', height: '50%' }}>
              
              {/* Render past events without Collapse */}
              {/* {pastEvents && renderPastEvents(pastEvents)} */}
            {/* </div> */} 
          </Box>
        </Box>

          {/* Footer at Bottom */}
          <Grid style={{ marginTop: '50px', width: '100%'}}>
            <Footer/>
          </Grid>
        </div>
      );
}

export default Events;