export default function Wordmark_white({width, height}) {
    return (
      <svg width={ width }height={ height } viewBox="0 0 245 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M90.7704 11.2119L89.7951 12.0743C88.0884 13.5836 89.3075 16.171 91.7457 16.171H97.5974C98.0851 16.171 98.3289 15.7398 98.3289 15.5241V10.9963C98.3289 10.565 97.8413 10.3494 97.5974 10.3494H92.9648C92.2333 10.3494 91.5019 10.565 90.7704 11.2119Z" fill="#FFFFFF"/>
        <path d="M95.1591 22.4238C97.1097 22.4238 98.5726 23.7174 98.5726 25.4424V45.2788C98.5726 47.0037 97.1097 48.2974 95.1591 48.2974C93.2085 48.2974 91.7456 47.0037 91.7456 45.2788V25.4424C91.7456 23.7174 93.2085 22.4238 95.1591 22.4238Z" fill="#FFFFFF"/>
        <path d="M52.0026 23.0706V47.4349C52.0026 47.8661 51.515 48.0818 51.2711 48.0818H46.1509C45.6632 48.0818 45.4194 47.6505 45.4194 47.4349C45.4194 46.7881 44.6879 46.5725 43.9565 47.0037C42.7374 48.0818 40.7868 48.513 38.5924 48.513C36.398 48.513 34.4474 48.0818 32.7407 47.2193C28.8395 45.2788 26.8889 41.6134 26.8889 37.5167V23.5018C26.8889 23.0706 27.3766 22.855 27.6204 22.855H32.7407C33.2283 22.855 33.4721 23.2862 33.4721 23.5018V37.5167C33.4721 40.3197 36.1542 42.6914 39.3239 42.6914C44.2003 42.6914 45.1756 39.2416 45.1756 34.9293V23.5018C45.1756 23.0706 45.6632 22.855 45.9071 22.855H51.0273C51.7588 22.4238 52.0026 22.6394 52.0026 23.0706Z" fill="#FFFFFF"/>
        <path d="M59.3174 47.2193V23.0706C59.3174 22.6394 59.805 22.4238 60.0488 22.4238H65.1691C65.6568 22.4238 65.9006 22.855 65.9006 23.0706C65.9006 23.7175 66.632 23.9331 67.3635 23.5018C68.5826 22.4238 70.5332 21.9926 72.7276 21.9926C74.922 21.9926 76.8726 22.4238 78.5793 23.2862C82.4805 25.2268 84.4311 28.8922 84.4311 32.9888V47.0037C84.4311 47.4349 83.9434 47.6505 83.6996 47.6505H78.5793C78.0917 47.6505 77.8479 47.2193 77.8479 47.0037V32.9888C77.8479 30.1859 75.1658 27.8141 71.9961 27.8141C67.1197 27.8141 66.1444 31.2639 66.1444 35.5762V47.0037C66.1444 47.4349 65.6568 47.6505 65.4129 47.6505H60.2927C59.805 48.0818 59.3174 47.6505 59.3174 47.2193Z" fill="#FFFFFF"/>
        <path d="M136.365 12.0743C133.196 10.3494 129.538 9.70258 125.393 9.70258C121.248 9.70258 117.591 10.565 114.421 12.0743C111.252 13.7992 108.813 15.9554 106.863 18.974C105.156 21.9925 104.181 25.2267 104.181 29.1078C104.181 32.9888 105.156 36.4386 106.863 39.2416C108.569 42.2602 111.252 44.4163 114.421 46.1412C117.591 47.8661 121.248 48.513 125.393 48.513C129.538 48.513 133.196 47.6505 136.365 46.1412C139.535 44.4163 141.973 42.2602 143.924 39.2416C145.63 36.223 146.606 32.9888 146.606 29.1078C146.606 25.2267 145.63 21.7769 143.924 18.974C141.973 15.9554 139.535 13.7992 136.365 12.0743ZM125.393 38.8104C123.199 38.8104 121.492 38.3792 120.273 37.7323C118.81 36.8699 117.835 35.7918 117.103 34.4981C116.372 32.9888 115.884 31.2639 115.884 29.3234C115.884 27.3829 116.372 25.658 117.103 24.1487C117.835 22.6394 118.81 21.5613 120.273 20.6989C121.736 19.8364 123.443 19.6208 125.393 19.6208C127.588 19.6208 129.294 20.052 130.513 20.6989C131.976 21.5613 132.952 22.6394 133.683 23.9331C134.415 25.4424 134.902 27.1673 134.902 29.1078C134.902 31.0483 134.415 32.7732 133.683 34.2825C132.952 35.7918 131.976 36.8699 130.513 37.5167C129.294 38.5948 127.588 38.8104 125.393 38.8104Z" fill="#FFA861"/>
        <path d="M171.963 26.3048L160.26 10.565C160.26 10.3494 160.016 10.3494 159.772 10.3494H150.263C150.019 10.3494 149.775 10.565 149.775 10.7807V47.6505C149.775 47.8662 150.019 48.0818 150.263 48.0818H160.504C160.747 48.0818 160.991 47.8662 160.991 47.6505V31.0483C160.991 30.6171 161.723 30.4015 161.967 30.8327L174.889 47.8662C174.889 48.0818 175.133 48.0818 175.377 48.0818H183.667C183.911 48.0818 184.154 47.8662 184.154 47.6505V10.7807C184.154 10.565 183.911 10.3494 183.667 10.3494H173.426C173.182 10.3494 172.939 10.565 172.939 10.7807V25.8736C172.939 26.5204 172.207 26.736 171.963 26.3048Z" fill="#FFA861"/>
        <path d="M201.466 37.7323V34.0669C201.466 33.8513 201.71 33.6357 201.953 33.6357H214.145C214.388 33.6357 214.632 33.4201 214.632 33.2044V24.3643C214.632 24.1487 214.388 23.9331 214.145 23.9331H201.953C201.71 23.9331 201.466 23.7175 201.466 23.5018V20.6989C201.466 20.4833 201.71 20.2676 201.953 20.2676H218.046C218.289 20.2676 218.533 20.052 218.533 19.8364V10.7807C218.533 10.565 218.289 10.3494 218.046 10.3494H190.494C190.25 10.3494 190.006 10.565 190.006 10.7807V47.6505C190.006 47.8662 190.25 48.0818 190.494 48.0818H218.777C219.021 48.0818 219.265 47.8662 219.265 47.6505V38.8104C219.265 38.5948 219.021 38.3792 218.777 38.3792H201.953C201.71 38.1636 201.466 37.9479 201.466 37.7323Z" fill="#FFA861"/>
      </svg>
    )
  }