import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar'; // Import the Navbar component
import About from './About';
import { ThemeProvider, createTheme } from '@mui/material';
import MainPage from './Main';
import Signup from './components/Signup';
import Login from './components/Login';
import Subscription from './Subscription';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import CheckoutSuccessPage from './CheckoutSuccessPage';
import Events from './Events';
import Ads from './Ads';
import Team from './Team';
import Organizations from './Organizations';
import { checkTokenExpiration } from './utils';
import { cookie } from './utils';
import { CookieNames } from './constants';
ReactGA.initialize('G-CC89E69G8Z', {
  debug: false
});

const theme = createTheme({
  palette: {
      primary: {
        main: '#F07A54'
      },
      secondary: {
        main: '#FFFFFF'
      }
  },
  typography: {
    fontFamily: '"Cabin", sans-serif'
  }
})

const stripePromise = loadStripe('pk_test_51Oq2XYI5wWlwCxFc5cE7pXrGa6fPJuojhMhcMfpIRqBtituOokwQmqvtXiPS0RgpWAhQ3zBAN4KwdNEVVFKKUYzq00KIEzKzfU');

const RouteWrapper = () => {
  // checkTokenExpiration();
  const authToken = cookie.get(CookieNames.AUTH_TOKEN);
  const orgId = cookie.get(CookieNames.ORG_ID);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const preChecks = async () => {
      try {
        const options = {headers: {'Authorization': `Bearer ${authToken}`}};
        const response = await fetch(`https://api.unione.cc/organizations/organization?id=${orgId}`, options);
        if (response.status === 200) {
          const data = await response.json();
          setIsSubscribed(data[0].subscription_end !== null && new Date(data[0].subscription_end).getTime() > Date.now());
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    preChecks();
  }, [authToken, orgId])

  useEffect(() => {
    if (authToken && isSubscribed) {
      window.location.href = 'http://localhost:3001/business-app';
      return;
    }
  }, [loading, authToken, isSubscribed])

  if (loading) {
    return <div>Loading...</div>
  }

  // No redirection needed
  return <Outlet />;
}

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    checkTokenExpiration();
  }, []);
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Router>
          <div>
            <Navbar /> {/* This places the Navbar at the top of all pages */}

            <Elements stripe={stripePromise}> {/* Wrap Routes with Elements */}
              <Routes>
                <Route path="" element={<RouteWrapper />} >
                <Route path="/" element={<MainPage />} />
                <Route path="/about" element={<About />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/pricing" element={<Subscription/>} />
                <Route path='/team' element={<Team/>} />
                <Route path="/payment-success" element={<CheckoutSuccessPage/>}/>
                <Route path='/ads' element={<Ads/>}/>
                <Route path="/events" element={<Events/>} />
                <Route path="/Promotions" element= {<Ads/>} />
                <Route path="/Team" element= {<Team/>} />
                <Route path="/organizations" element={<Organizations/>} />
                </Route>
              </Routes>
            </Elements>
          </div>
        </Router>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;