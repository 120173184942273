// Import necessary components and assets
import { Typography, Box, useMediaQuery, useTheme, Button, Grid } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { Parallax } from 'react-parallax';
import React, { useState, useEffect } from 'react';

// Import images and analytics utilities
import Sec2 from '../src/Assets/slideshow.png'
import iphone from '../src/Assets/iPhone14.png';
import adding_event from '../src/Assets/adding_event.png'; 
import event_analytics from '../src/Assets/event_analytics.png';
import event_detail from '../src/Assets/event_detail.png';
import event_list from '../src/Assets/events_list.png';
import Match from '../src/Assets/Match.png';
import Card from '../src/Assets/Card.png';
import Schedule from '../src/Assets/Schedule.png';
import Profile from '../src/Assets/Profile.png';
import Footer from './components/Footer';
import analytics from './analytics'; // Assuming this is for tracking page views or events

// Initial analytics call to track page view
analytics.page();

const features = [
  { title: 'Match!', phoneImage: Match },
  { title: 'Unique Cards', phoneImage: Card },
  { title: 'Schedule', phoneImage: Schedule },
  { title: 'Profile', phoneImage: Profile },
];
const dashboardFeatures = [   
    { title: 'List Of Events', dashboardImage: event_list },
    { title: 'Adding Events', dashboardImage: adding_event },
    { title: 'Event Details', dashboardImage: event_detail },
    { title: 'Analytics', dashboardImage: event_analytics },
];


function About() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
     // State hooks for controlling selected features and color transitions based on scroll
    const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0);
    const [selectedDashboardIndex, setSelectedDashboardIndex] = useState(0);
    const [backgroundColor, setBackgroundColor] = useState('rgba(239,123,84,1)'); // Initial background color
    const [textColor, setTextColor] = useState("black");
// Effect hook for handling dynamic background and text color changes on scroll
    useEffect(() => {
      const handleScroll = () => {
          const scrollPosition = window.scrollY;
          const windowHeight = window.innerHeight;
          const docHeight = document.documentElement.scrollHeight;
          const totalScrollableDistance = docHeight - windowHeight;
          const startTransitionAt = totalScrollableDistance * 0.2;
          const endTransitionAt = totalScrollableDistance * 0.45; // End of transition to black
          const startOrangeTransitionAt = totalScrollableDistance * 0.57; // Start transitioning to orange
          const endOrangeTransitionAt = totalScrollableDistance * 0.9; // Fully transitioned to orange
  
          let progress = 0;
          let color, textColor;
  
          if (scrollPosition > startTransitionAt && scrollPosition < endTransitionAt) {
              // Transition from white to black
              progress = (scrollPosition - startTransitionAt) / (endTransitionAt - startTransitionAt);
              color = `rgba(${239 - progress * 239}, ${123 - progress * 123}, ${84 - progress * 84})`;
              textColor = `rgba(${39 + progress * (216)}, ${39 + progress * (216)}, ${35 + progress * (220)})`; // Adjust text color as needed
          } else if (scrollPosition >= endTransitionAt && scrollPosition < startOrangeTransitionAt) {
              // Stay black
              color = 'rgba(0, 0, 0, 1)';
              textColor = 'rgba(255, 255, 255, 1)'; // Text color for black background
          } else if (scrollPosition >= startOrangeTransitionAt && scrollPosition < endOrangeTransitionAt) {
              // Transition to orange
              progress = (scrollPosition - startOrangeTransitionAt) / (endOrangeTransitionAt - startOrangeTransitionAt);
              color = `rgba(${0 + progress * 239}, ${0 + progress * 123}, ${0 + progress * 84})`; // Transitioning to orange (rgb(255, 255, 0))
              textColor = `rgba(${255 - progress * 216}, ${255 - progress * 216}, ${255 - progress * 220})`; // Adjust text color as needed
          } else if (scrollPosition >= endOrangeTransitionAt) {
              // Stay orange
              color = 'rgba(239, 123, 84)';
              textColor = 'rgba(0, 0, 0, 1)'; // Text color for orange background
          }
  
          setBackgroundColor(color);
          setTextColor(textColor);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);
// Effect hook for auto-scrolling through features and dashboard functionalities
    useEffect(() => {
        const intervalId = setInterval(() => {
            setSelectedFeatureIndex(prevIndex => prevIndex === features.length - 1 ? 0 : prevIndex + 1);
            setSelectedDashboardIndex(prevIndex => prevIndex === dashboardFeatures.length - 1 ? 0 : prevIndex + 1);
        }, 6000); // Change slide every 4 seconds for both features and dashboard features

        return () => clearInterval(intervalId);
    },);

    const handleChangeIndex = (index) => {
        setSelectedFeatureIndex(index);
    };

    const handleChangeDashboardIndex = (index) => {
        setSelectedDashboardIndex(index);
    };

    return (
        // Main Container
        <>
            {/* Introductory section with dynamic layout for mobile vs desktop */}
            <Box
            
                display="flex"
                flexDirection={isMobile ? 'column-reverse' : 'row'}
                justifyContent="center"
                alignItems="center"
                minHeight="70vh"
                bgcolor="#272723"
                p={2}
                sx={{
                    position: 'relative',
                }}
            >
                
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems={isMobile ? 'center' : 'flex-start'} // Center on mobile, align to start on larger screens
                    sx={{
                        maxWidth: isMobile ? '100%' : '50%', // Take full width on mobile, half on larger screens
                        textAlign: isMobile ? 'center' : 'left', // Center text on mobile
                        order: isMobile ? 1 : 0, // Ensure text is above the image on mobile
                        marginRight: 'auto',
                        marginLeft: 'auto',
                    }}
                >
                    <Typography variant="h3" gutterBottom color="white"
                        sx={{
                            fontWeight: 'bold',
                            fontFamily: '"Cabin", sans-serif'
                        }}>
                        uniONE: Connect, Engage, Thrive.
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom color="white"
                        sx={{
                            fontWeight: 'bold',
                            fontFamily: '"Cabin", sans-serif'
                        }}>
                        Your portal to business-student engagement
                    </Typography>
                    <Typography variant="h4" gutterBottom color="white"
                        sx={{
                            fontWeight: 'bold',
                            fontFamily: '"Cabin", sans-serif'
                        }}>
                        Download, Discover, Grow!
                    </Typography>
                </Box>
                {!isMobile && (
                    <Box
                        component="img"
                        src={iphone}
                        sx={{
                            maxWidth: '50%',
                            height: 'auto',
                            order: 2, // Image on the right side for larger screens
                            marginRight: 'auto',
                            marginTop: '1rem',
                            marginBottom: '1rem',
                            borderRadius: '10px',
                        }}
                    />
                )}
            </Box>
                  {/* Parallax effect section showcasing 'Who We Are' with dynamic text content */}      
            <Parallax
                bgImage={Sec2}
                strength={500}
                bgImageStyle={{ minHeight: '100%' }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    minHeight="80vh"
                    color="white"
                    sx={{
                        mt: '-100px',
                        width: '100%', 
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        my:2
                    }}
                >
                    <Box
                        alignItems="center"
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            padding: '2rem',
                            borderRadius: '8px',
                            mx: 'auto',
                            my: 4,
                            maxWidth: '1000px',
                            width: '100%',
                            boxSizing: 'border-box',
                        }}
                    >
                        <Typography variant="h2" gutterBottom align="center"
                            sx={{
                                fontWeight: 'bold',
                                fontFamily: '"Cabin", sans-serif'
                            }}>
                            Who We Are
                        </Typography>
                        <Typography variant="h6" align="center"
                            sx={{
                                fontWeight: 'bold',
                                fontFamily: '"Cabin", sans-serif'
                            }}>
                            At uniONE, we are innovators and connectors at the heart of the academic and business nexus. Our team, a dynamic blend of technology enthusiasts and market strategists, is dedicated to bridging the gap between students and local businesses. With a deep understanding of the unique challenges and opportunities in the academic community, we have developed a specialized Dashboard that empowers businesses and clubs to engage effectively with the student population. Our platform is more than just a tool; it's a thriving ecosystem where businesses can attract, interact, and grow alongside a vibrant, youthful audience. UniOne stands as a testament to our commitment to fostering a more interconnected, supportive, and prosperous community for all
                        </Typography>
                    </Box>

                </Box>
            </Parallax>
            {/* App Features section with swipeable views for feature showcase */}
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="90vh"
                bgcolor={backgroundColor}
                p={2}
                sx={{
                    position: 'relative',
                }}
            >
                <Typography
                    variant="h2"
                    gutterBottom
                    color={textColor}
                    textAlign="center"
                    sx={{
                        fontWeight: 'bold',
                        fontFamily: '"Cabin", sans-serif'
                    }}
                >
                    App Features
                </Typography>
                <Box
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        padding: { xs: '1rem', sm: '2rem' }, // Responsive padding
                        borderRadius: '50px',
                        height: '100%',
                        mx: 'auto', // Use auto margins for horizontal centering
                        my: 2, // Consistent vertical margin
                        maxWidth: '1000px',
                        width: '100%', // Use full width
                        boxSizing: 'border-box',
                        fontWeight: 'bold',
                        fontFamily: '"Cabin", sans-serif'
                    }}
                >
                    {/* Feature Selection for Desktop */}
                    {!isMobile && (
                        <Box display="flex" justifyContent="center" mb={4}>
                            {features.map((feature, index) => (
                                <Button
                                    key={feature.title}
                                    onClick={() => handleChangeIndex(index)}
                                    sx={{
                                        bgcolor: selectedFeatureIndex === index ? '#FCFF72' : '#272723',
                                        color: selectedFeatureIndex === index ? 'black' : '#FFF7F2',
                                        m: { xs: 2, sm: 4 }, // Responsive margins
                                        p: 2,
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        borderRadius: '8px',
                                        '&:hover': {
                                            bgcolor: selectedFeatureIndex === index ? '#FFF7F2' : '#272723',
                                            color: selectedFeatureIndex === index ? '#272723' : '#FFF7F2',
                                        },
                                        width: '200px',
                                        height: '100px',
                                        border: selectedFeatureIndex === index ? '2px #272723' : 'none',
                                    }}
                                >
                                    {feature.title}
                                </Button>
                            ))}
                        </Box>
                    )}

                    {/* Feature Selection for Mobile */}
                    {isMobile && (
                        <SwipeableViews
                            index={selectedFeatureIndex}
                            onChangeIndex={handleChangeIndex}
                            enableMouseEvents
                            resistance
                        >
                            {features.map((feature, index) => (
                                <Box key={feature.title} p={3} textAlign="center" sx={{ bgcolor: '#272723', p: 2, borderRadius: '8px', maxWidth: '85vw', m: 'auto', mb: { xs: 2, sm: 4 } }}>
                                    <Typography variant="h6" color="white">
                                        {feature.title}
                                    </Typography>
                                </Box>
                            ))}
                        </SwipeableViews>
                    )}

                    {/* Phone Image Display */}
                    <SwipeableViews
                        index={selectedFeatureIndex}
                        onChangeIndex={handleChangeIndex}
                        enableMouseEvents
                        resistance
                    >
                        {features.map((feature, index) => (
                            <Box
                                key={feature.title}
                                sx={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    overflow: 'hidden',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img src={feature.phoneImage} alt="Phone" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                            </Box>
                        ))}
                    </SwipeableViews>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 2, // Adjust margin as needed
                        }}
                    >
                        {features.map((feature, index) => (
                            <Box
                                key={index}
                                onClick={() => handleChangeIndex(index)} 
                                sx={{
                                    width: 15,
                                    height: 15, 
                                    borderRadius: '50%',
                                    backgroundColor: selectedFeatureIndex === index ? 'primary.main' : 'grey.400',
                                    mx: 0.5,
                                    cursor: 'pointer',
                                }}
                            />
                        ))}
                    </Box>

                </Box>
            </Box>


            {/* Dashboard Features section with swipeable views and side navigation for features */}
        
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="90vh"
                bgcolor="#272723"
                p={2}
                sx={{
                    background: `linear-gradient(180deg, ${backgroundColor} 30%, #272723 100%)`,
                    position: 'relative',
                    '@media (max-width: 600px)': {
                        padding: '1rem', // Adjusting padding for small screens
                    },
                }}
                >
                <Typography variant="h2" gutterBottom color="white" sx={{ 
                    mb: 4, 
                    fontWeight: 'bold', 
                    textAlign: 'center', 
                    fontSize: { xs: '1.75rem', sm: '2rem', md: '3rem' }, // Increased font size for extra small screens
                    textShadow: '2px 2px 8px rgba(0,0,0,0.8)', // Adding text shadow for better readability
                }}>
                    Explore Our Dashboard
                </Typography>
                <Typography variant="subtitle1" color="white" sx={{ 
                    mb: 4, 
                    textAlign: 'center', 
                    maxWidth: '80%', 
                    mx: 'auto', 
                    fontSize: { xs: '1rem', sm: '1.25rem' }, // Increased font size for extra small screens
                    textShadow: '1px 1px 4px rgba(0,0,0,0.6)', // Adding text shadow for better readability
                }}>
                    Dive into the heart of uniONE's functionality with our intuitive and feature-rich dashboard. Designed to streamline your experience, it offers a seamless interface for managing connections, engaging with content, and accessing a wealth of resources at your fingertips.
                </Typography>

                <Grid container spacing={2} alignItems="center" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                    <Grid item xs={12} sm={3} sx={{
                        pr: 0,
                        mr: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: { xs: 'center', sm: 'flex-start' },
                        '@media (max-width: 600px)': {
                            width: '100%', // the container takes full width on small screens
                            marginBottom: '20px', // Add some margin at the bottom for spacing
                        }
                    }}>
                        {dashboardFeatures.map((feature, index) => (
                            <Box
                                key={feature.title}
                                onClick={() => handleChangeDashboardIndex(index)}
                                sx={{
                                    bgcolor: index === selectedDashboardIndex ? 'primary.main' : '#272723', // Highlight the active feature
                                    color: 'white',
                                    display: { xs: index === selectedDashboardIndex ? 'flex' : 'none', sm: 'flex' },
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    p: 1,
                                    mb: 2,
                                    mt: 0,
                                    ml: { xs: 0, sm: 15 },
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        bgcolor: index === selectedDashboardIndex ? 'primary.dark' : 'rgba(255, 255, 255, 0.2)', // Darken on hover
                                    },
                                    width: { xs: '100%', sm: '200px' },
                                    height: '100px',
                                    '@media (max-width: 1400px)': {
                                        ml: 0,
                                        mb: 1,
                                        height: '80px',
                                        width: '80%'
                                    },
                                }}
                            >
                                <Typography variant="h6" noWrap sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                                    {feature.title}
                                </Typography>
                            </Box>
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={9} sx={{
                        pl: 0,
                        '@media (max-width: 600px)': {
                            paddingLeft: '0', // Adjusting padding as necessary for small screens
                        }
                    }}>
                        <SwipeableViews
                            index={selectedDashboardIndex}
                            onChangeIndex={handleChangeDashboardIndex}
                            enableMouseEvents
                            resistance
                        >
                            {dashboardFeatures.map((feature, index) => (
                                <Box
                                    key={feature.title}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        p: 2,
                                    }}
                                >
                                    <img src={feature.dashboardImage} alt="Dashboard feature" style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />
                                </Box>
                            ))}
                        </SwipeableViews>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mt: 2, // Adjust margin as needed
                            }}
                        >
                            {dashboardFeatures.map((feature, index) => (
                                <Box
                                    key={index}
                                    onClick={() => handleChangeDashboardIndex(index)} 
                                    sx={{
                                        width: 15, 
                                        height: 15, 
                                        borderRadius: '50%',
                                        backgroundColor: selectedDashboardIndex === index ? 'primary.main' : 'grey.400',
                                        mx: 0.5, 
                                        cursor: 'pointer',
                                    }}
                                />
                            ))}
                        </Box>

                    </Grid>
                </Grid>
                {/* Footer with custom text color */}
                <Grid style={{ marginTop: '200px', width: '100%' }}>
                    <Footer textColor="common.white" />
                </Grid>
            </Box>
                       
        </>
  );
}

export default About;

