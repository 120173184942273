import React from 'react';
import Footer from './components/Footer';
import { Box, Typography, Grid } from '@mui/material';
import Vardan from '../src/Assets/VardanSaini.jpeg';
import Mark_unione from '../src/Assets/Mark_Unione.jpeg';
import Sanket_Web  from  '../src/Assets/Sanket_Web_Unione.jpeg'
import Alex_Web from '../src/Assets/Alex_Web_Unione.png';
import Aaron_Web from  '../src/Assets/Aaron_Web_Unione.jpg';
import Ildar_prof  from "../src/Assets/Ildar_Prof.jpeg";
import Mark_prof from  "../src/Assets/Mark_Prof.jpeg";
import Raymond_App from  "../src/Assets/Raymond_App_Unione.png" ;
import Shanemel_App from  "../src/Assets/Shanemel_App_Unione.png";
import Triaksh_Dashboard from  "../src/Assets/Triaksh_Dashboard_Unione.png";
import eHUB from "../src/Assets/eHUB.jpeg";
import Derek_App from  "../src/Assets/Derek_App_Unione.jpeg";
import Jacqueline_App from  "../src/Assets/Jacqueline_App_Unione.jpeg";
import Gary_App from   "../src/Assets/Gary_App_Unione.jpeg";
import minion from  "../src/Assets/minion.jpeg";
import Mark_Web from  "../src/Assets/Mark_Web_Unione.jpg";
import Caleb_Web from "../src/Assets/Caleb_Web_Unione.jpg"
function Team() {
    const teamData = {
        Leadership: [
            {
                name: 'Vardan Saini',
                role: 'Co-Founder and CEO',
                details: '',
                imageUrl: Vardan,
            },
            {
                name: 'Loyaljot Singh Bajwa',
                role: 'Co-Founder',
                details: '',
                imageUrl: '', 
            },
            {
                name: 'Kashish Sansanwal',
                role: 'Co-Founder',
                details: '',
                imageUrl: '', 
            },
            {
                name: 'Mark Usmanov',
                role: 'Lead Software Engineer',
                details: '',
                imageUrl: Mark_unione, 
            },
        ],
        unioneWebsiteDevelopers: [
            {
                name: 'Sanket Lamba',
                role: 'uniONE Website Developers',
                imageUrl: Sanket_Web,
            },
            {
                name: 'Aaron Binoy',
                role: 'uniONE Website Developers',
                imageUrl: Aaron_Web,
            },
            {
                name: 'JunCheng Yuan',
                role: 'uniONE Website Developers',
                imageUrl: Alex_Web,
            },
            {
                name: 'Kevin Wu',
                role: 'uniONE Website Developers',
                imageUrl: '',
            },
            {
                name: 'Mark McGoey',
                role: 'uniONE Website Developers',
                imageUrl: Mark_Web,
            },
            {
                name: 'Caleb Lonson',
                role: 'uniONE Website Developers',
                imageUrl: Caleb_Web,
            },
        ],
        unioneAppDevelopers: [
            {name: 'Derek Trinh',
                role: 'uniONE App Developer',
            imageUrl: Derek_App,},

            {name: 'Jacqueline Denise F Azarcon',
                role: 'uniONE App Developer',
            imageUrl: Jacqueline_App ,},

            {name: 'Gary Ng Kwong Sang',
                role: 'uniONE App Developer',
            imageUrl: Gary_App,},

            {name: 'Natnail Ghebresilasie',
                role: 'uniONE App Developer',
            imageUrl: '',},

            {
                name: 'Raymond Mo',
                role: 'uniONE App Developer',
                imageUrl: Raymond_App,
            }, 
            {
                name: 'Shanemel Leigh Asuncion',
                role: 'uniONE App Developer',
                imageUrl: Shanemel_App,
            }, 
            {
                name: 'Truibg-Giang Pham',
                role: 'uniONE App Developer',
                imageUrl: '',
            }, 
    
        ],
        unioneDashboardDevelopers: [
            {
                name: 'Siddharth Chhatbar',
                role: 'uniONE Dashboard Developers',
                imageUrl: '',
            },
            {
                name: 'Shrey Mandol',
                role: 'uniONE Dashboard Developers',
                imageUrl: '',
            },
            {
                name: 'Triaksh Mahta',
                role: 'uniONE Dashboard Developers',
                imageUrl: Triaksh_Dashboard,
            },
            {
                name: 'Aron Rajabi',
                role: 'uniONE Dashboard Developers',
                imageUrl: '',
            },
            {
                name: 'Tianxiang Ren',
                role: 'uniONE Dashboard Developers',
                imageUrl: '',
            },
            {
                name: 'Mark Usmanov',
                role: 'uniONE Dashboard Developers',
                imageUrl: '',
            },
            {
                name: 'Yuxuan Yang',
                role: 'uniONE Dashboard Developers',
                imageUrl: '',
            }, 
        ],
        
        specialThanks: [
            {
                name: 'Mark Polak',
                role: 'CMPUT 401 Professor',
                imageUrl: Mark_prof,
            },
            {
                name: 'Ildar Akmetov',
                role: 'CMPUT 401 Professor',
                imageUrl: Ildar_prof,
            },
            {
                name: 'eHub',
                role: 'On Campus Incubator',
                imageUrl: eHUB,
            },
        ],
    };

    return (
        <Box bgcolor="#f5f5f5" color="#000" minHeight="100px">
            {/* Promotions header section */}
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="800px"
                bgcolor="#272723"
                boxShadow={3}
                sx={{ margin: 'auto', marginBottom: '20px', width: '100%', textAlign: 'center' }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        padding: '10px',
                        borderRadius: '5px',
                        color: 'white',
                        fontWeight: 'bold',
                        fontFamily: '"Cabin", sans-serif',
                    }}
                >
                    Meet Our Team
                </Typography>
            </Box>
            {Object.entries(teamData).map(([category, members]) => (
                <Box key={category} sx={{ mb: 5 }}>
                    <Typography variant="h4" sx={{ textAlign: 'center', mt: 4, mb: 2, color: '#272723', fontWeight: 'bold' }}>
                        {formatCategoryName(category)}
                    </Typography>
                    <Grid container spacing={4} justifyContent="center">
                        {members.map((member, index) => (
                            <Grid item xs={12} sm={6} md={4} key={`${category}-${index}`}>
                                <Box
                                    bgcolor="#ffffff"
                                    boxShadow="0px 4px 20px rgba(0,0,0,0.1)"
                                    borderRadius={2}
                                    p={2}
                                    textAlign="center"
                                    sx={{
                                        marginX: '16px', 
                                        '& img': {
                                            width: '150px', 
                                            height: '150px', 
                                            borderRadius: '50%', 
                                            objectFit: 'cover',
                                        },
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={member.imageUrl || minion}
                                        alt={`${member.name}`}
                                    />
                                    <Typography variant="h5" gutterBottom>
                                        {member.name}
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        {member.role}
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                        {member.details}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ))}
            <div style={{ marginTop: '200px', width: '100%' }}>
                <Footer textColor="common.black" />
            </div>
        </Box>
    );
}

function formatCategoryName(categoryName) {
    return categoryName
        .replace(/([A-Z])/g, ' $1')
        .replace(/one/, (str) => str.toUpperCase())
        .trim();
}

export default Team;
