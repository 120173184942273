import { Box, SvgIcon, TextField, Button, Typography, Grid, Link } from '@mui/material';
import UnioneLogo from './Logo';
import Wordmark from './Wordmark/Wordmark';
import { useState, useEffect } from 'react'; 
import EastIcon from '@mui/icons-material/East';

function Signup() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [bio, setBio] = useState('');
  const [preferredName, setPreferredName] = useState('');
  const [major, setMajor] = useState('');
  const [year, setYear] = useState('');

  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [badInput, setBadInput] = useState(false);

  const emailRegex = /.+@.+\..+/;
  const handleSubmit = async (event) => {
    event.preventDefault();
    setEmailError(!email || !emailRegex.test(email));
    setFirstNameError(!firstName);
    setLastNameError(!lastName);
    setPasswordError(!password);

    const isInputValid = email && firstName && lastName && password && bio && preferredName && major && year && emailRegex.test(email);

    if (isInputValid) {
      const payload = {
        firstName,
        lastName,
        bio,
        email,
        preferredName,
        major,
        year,
        password
      };
      try {
        const response = await fetch('https://api.unione.cc/logins/signup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          console.log("Signup successful!");
          // After successful signup, redirect to the login page
          window.location.href = '/login';
        } else {
          console.error("Signup failed:", await response.text());
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      setBadInput(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        bgcolor: '#272723',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '5px',
          paddingX: { xs: 5, sm: 10 },
          paddingY: { xs: 5, sm: 10 },
          marginX: 2,
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SvgIcon component={UnioneLogo} width='4rem' height='4rem'/>
          <SvgIcon component={Wordmark} width='12.5rem' height='3rem' />
        </Box>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Typography color="error" fontWeight={600} display={badInput ? "" : "none"}>Invalid or missing inputs</Typography>
          <Grid container spacing={{ xs: 1, sm: 2 }} maxWidth='sm'>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="preferredName"
              label="Preferred Name"
              name="preferredName"
              onChange={e => setPreferredName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="major"
              label="Major"
              name="major"
              onChange={e => setMajor(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="year"
              label="Year"
              name="year"
              type="number"
              onChange={e => setYear(e.target.value)}
              InputProps={{
                inputProps: { 
                  min: 1, max: 6 
                }
              }}
            />
          </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoComplete="given-name"
                autoFocus
                onChange={e => setFirstName(e.target.value)}
                error={firstNameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                onChange={e => setLastName(e.target.value)}
                error={lastNameError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                type='email'
                onChange={e => setEmail(e.target.value)}
                error={emailError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                onChange={e => setPassword(e.target.value)}
                error={passwordError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="bio"
                label="Bio"
                name="bio"
                multiline
                rows={4}
                onChange={e => setBio(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, color: "#FFFFFF", bgcolor: "#EF7B54", ":hover": { bgcolor: "#FFFFFF", color: '#EF7B54' }}}
            endIcon={<EastIcon />}
            >
            Sign Up
          </Button>
          <Grid container justifyContent="center">
          <Grid item>
            <Typography fontWeight={600}>Already have an account? <Link href="/login" sx={{ color: "#FAB278", textDecoration: 'none', "&:hover": { color:"#EF7B54" }}}>Sign in</Link></Typography>
          </Grid>
        </Grid>
        </Box>
    </Box>
  </Box>
  );
}

export default Signup;
